<template lang="pug">
    div(class="sz-UserTable")
        div(class="sz-UserTable-optionsBar")
            div(
                @click.stop="toggleEditUser",
                class="sz-UserTable-optionsBar-edit") {{ $t(`management.companyDashboard.edit`) }}
                SvgIcon(
                    icon="arrow",
                    width="0.5rem",
                    height="0.5rem",
                    class="sz-UserTable-optionsBar-edit-icon")
                transition(name="slide")
                    DropdownEditUser(
                        v-if="showEditOptions",
                        @closeEditDropdown="closeEditDropdown",
                        :users="selectedUsers")
            div(class="sz-UserTable-optionsBar-search")
                input(
                    type="text",
                    v-model="search",
                    placeholder="Type here to search",
                    class="sz-UserTable-optionsBar-search-input")
                SvgIcon(
                    icon="search",
                    width="0.5rem",
                    height="0.5rem",
                    class="sz-UserTable-optionsBar-search-icon")
        div(class="sz-UserTable-wrapper")
            table(class="sz-UserTable-table sticky-header")
                thead
                    tr(class="sz-UserTable-headers")
                        th()
                            div(
                                @click.stop="toggleSelectAll",
                                :class="`sz-UserTable-row-checkbox-` + (isSelectAll ? 'checked ': 'unchecked')",
                                class="sz-UserTable-row-checkbox")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-UserTable-headers-header-${header}`")
                            div(class="sz-UserTable-headers-header-content")
                                span {{ $t(`management.companyDashboard.userTable.headers.${header}`) }}
                                SvgIcon(
                                    icon="arrow",
                                    width="10",
                                    height="10",
                                    class="sz-UserTable-headers-header-arrow",
                                    :class="[currentSortDirection, header === currentSort ? 'show' : '']")
                tbody
                    tr(
                        v-if="companyUserListLoading || !users.length",
                        class="sz-UserTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-UserTable-empty-cell")
                            span(
                                v-if="companyUserListLoading"
                                class="sz-UserTable-empty-cell-loading") {{ $t(`management.companyDashboard.userTable.loading`) }}
                            span(
                                v-else-if="!users.length"
                                class="sz-UserTable-empty-cell-loading") {{ $t(`management.companyDashboard.userTable.empty`) }}
                    tr(
                        v-if="!companyUserListLoading && users.length",
                        v-for="user of users",
                        :key="user.userID",
                        class="sz-UserTable-row")
                        td()
                            div(
                                @click.stop="userSelected(user)"
                                :class="`sz-UserTable-row-checkbox-` + (isUserSelected(user) ? 'checked ': 'unchecked')",
                                class="sz-UserTable-row-checkbox"
                                )
                        td(
                            @click="openEditUserModal(user)",
                            v-for="header of headers",
                            :key="header",
                            v-truncated-tooltip="",
                            :id="`sz-UserTable-row-item-${header}-${user.email}`"
                            :class="`sz-UserTable-row-item ${header}`"
                            :data-personal-info="isPersonalInfo(header)") {{ user[header] ?  joinUserItems(user[header]) : null }}
</template>

<script>
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import { cloneDeep, get } from 'lodash'
import { TruncatedTooltip } from 'directives/truncatedTooltip'
import { userAssignableRoles } from 'helpers/permissionsHelper'
import modalSettings from 'mixins/modalSettings'
import SvgIcon from 'components/Shared/SvgIcon'
import DropdownEditUser from './DropdownEditUser'
import EventBus from 'src/eventBus'
import { splitFirstNameLastName } from '@/helpers/utilities'
import { userIsLBAdmin } from '@/helpers/permissionsHelper'
import { getInstance } from 'auth/index'

export default {
  name: 'UserTable',

  components: {
    SvgIcon,
    DropdownEditUser,
  },

  directives: {
    TruncatedTooltip,
  },

  mixins: [modalSettings],

  data() {
    return {
      currentSort: constants.USER_CHARACTERISTICS.EMAIL,
      currentSortDirection: 'down',
      search: '',
      selectedUsersIDs: [],
      selectAll: false,
      showEditOptions: false,
    }
  },

  computed: {
    ...mapGetters(['companyUserListLoading', 'allCompanyUsers', 'auth0User']),

    headers() {
      return userIsLBAdmin(getInstance().user)
        ? constants.USER_INFO_TABLE_HEADERS
        : constants.USER_INFO_TABLE_HEADERS.filter(
            (header) => header !== constants.USER_CHARACTERISTICS.ANIMATOR
          )
    },

    accessibleRoles() {
      return userAssignableRoles(this.auth0User)
    },

    users() {
      // in the future, this list will be paginated. need to implement a page switcher for paginated results.
      return this.allCompanyUsers
        .map((user) => {
          user['viewertags'] = user.viewertags.map((tag) => tag.name || tag)
          user['membertags'] = user.membertags.map((tag) => tag.name || tag)
          user['userVisible'] = this.$t(
            `management.${constants.USER_VISIBILITY_MAP[user.anonymousMode.userVisible]}`
          )
          // Need to re-assign this to a new variable name because animatorVisable is a boolean
          // and used in the API request
          // Assigning 'animator' to display the value of 'Yes' or 'No'
          user['animator'] =
            user.animatorVisible === true
              ? this.$t(`management.visible`)
              : this.$t(`management.hidden`)
          return user
        })
        .filter((user) => {
          let fullName = splitFirstNameLastName(this.search.toLowerCase())
          if (user.email) {
            const showUser =
              user.roles.length === 0
                ? true
                : this.accessibleRoles.some((accessibleRole) => user.roles.includes(accessibleRole))
            return (
              showUser &&
              (user.email.trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1 ||
                get(user, 'firstName', '')
                  .trim()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase().trim()) > -1 ||
                get(user, 'lastName', '')
                  .trim()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase().trim()) > -1 ||
                (get(user, 'firstName', '').trim().toLowerCase().indexOf(fullName.firstName) > -1 &&
                  get(user, 'lastName', '').trim().toLowerCase().indexOf(fullName.lastName) > -1))
            )
          }
          return -1
        })
        .sort((a, b) => {
          let direction = 1
          let valA = this.sortCaseInsensitive
            ? get(a, this.currentSort, '').toLowerCase()
            : get(a, this.currentSort, '')
          let valB = this.sortCaseInsensitive
            ? get(b, this.currentSort, '').toLowerCase()
            : get(b, this.currentSort, '')
          if (this.currentSortDirection === 'down') direction = -1
          if (valA === null || valA === '' || valA.length === 0) return direction
          if (valB === null || valB === '' || valB.length === 0) return -1 * direction
          if (valA < valB) return -1 * direction
          if (valA > valB) return direction
          return 0
        })
    },

    selectedUsers() {
      return this.allCompanyUsers.filter((user) => this.selectedUsersIDs.includes(user.userID))
    },

    isSelectAll() {
      return this.selectAll && this.selectedUsersIDs.length === this.users.length
    },

    sortCaseInsensitive() {
      return (
        this.currentSort === constants.USER_CHARACTERISTICS.EMAIL ||
        this.currentSort === constants.USER_CHARACTERISTICS.F_NAME ||
        this.currentSort === constants.USER_CHARACTERISTICS.L_NAME
      )
    },
  },

  mounted() {
    if (!this.allCompanyUsers || this.allCompanyUsers.length === 0) {
      this.$store.dispatch('getAllUsersInCompany')
    }

    EventBus.$on('UPDATE_SELECTED_USERS', (userID) => {
      this.selectedUsersIDs = this.selectedUsersIDs.filter((user) => user !== userID)
    })
  },

  methods: {
    setCurrentSort(header) {
      if (header === this.currentSort) {
        this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
      }
      this.currentSort = header
    },

    openEditUserModal(user) {
      this.$store.dispatch(
        'showModal',
        this.constructModalSettings(
          constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_USER,
          false,
          { user },
          true,
          true
        )
      )
    },

    isUserSelected(user) {
      return this.selectedUsersIDs.some((selectUser) => selectUser === user.userID)
    },

    userSelected(user) {
      if (this.isUserSelected(user)) {
        this.unSelectUser(user)
        return false
      } else {
        this.selectedUsersIDs.push(user.userID)
        return true
      }
    },

    unSelectUser(selectedUser) {
      let currentlySelected = this.selectedUsersIDs.filter(
        (userID) => userID !== selectedUser.userID
      )
      this.selectedUsersIDs = currentlySelected
    },

    toggleSelectAll() {
      this.selectAll
        ? (this.selectedUsersIDs = [])
        : (this.selectedUsersIDs = this.users.map((user) => user.userID))
      this.selectAll = !this.selectAll
    },

    toggleEditUser() {
      if (this.selectedUsers.length === 1) {
        this.openEditUserModal(this.selectedUsers[0])
      } else {
        this.showEditOptions = !this.showEditOptions
      }
    },

    closeEditDropdown() {
      this.showEditOptions = false
    },

    joinUserItems(array) {
      if (Array.isArray(array) && array.length > 1) {
        return array.join(', ')
      } else {
        return array.toString()
      }
    },

    isPersonalInfo(header) {
      return [
        constants.USER_CHARACTERISTICS.ROLE,
        constants.USER_CHARACTERISTICS.TIME_ZONE,
        constants.USER_CHARACTERISTICS.USER_VISIBLE,
      ].includes(header)
        ? null
        : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

table,
th,
tr,
td {
  border: none;
}

th {
  cursor: default;
  color: #e5e5e5;
}

td,
th {
  padding: 0.5rem;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  overflow: auto;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 11px;
  flex-grow: 1;
  background-color: $color-box-background;
  color: $color-unselected-text;

  &.sticky-header th {
    position: sticky;
    top: 0;
    background-color: $color-box-background;
  }
}

.sz-UserTable {
  display: flex;
  flex-direction: column;
  &-optionsBar {
    display: flex;
    background-color: $color-header-bar-backround;
    align-items: center;
    padding: 0.5rem;
    font-size: 13px;

    @extend %font-heading;
    @include search-bar;

    &-edit {
      display: flex;
      align-items: center;
      &-icon {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    &-search {
      margin-left: auto;
      width: 30%;
      min-width: 9rem;
    }
  }

  &-wrapper {
    width: inherit;
    max-height: 60vh;
    overflow: scroll;
    @include scrollbar-widget;
    background-color: $color-box-background;
  }

  &-headers {
    &-header {
      &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
      }

      &-arrow {
        visibility: hidden;
        padding: 1px 3px;
      }

      &-email {
        width: 10%;
      }

      &-firstName {
        width: 9%;
        overflow: hidden;
      }

      &-lastName {
        width: 9%;
        overflow: hidden;
      }

      &-gender {
        width: 1%;
        padding-left: 1rem;
      }

      &-mobile {
        width: 11%;
      }

      &-roles {
        width: 9%;
      }

      &-viewertags {
        width: 11%;
      }

      &-membertags {
        width: 10%;
      }

      &-job {
        width: 11%;
      }

      &-timezone {
        width: 12%;
      }

      &-userVisible {
        width: 10%;
      }
    }
  }

  &-row {
    cursor: pointer;

    &:hover {
      background: $color-table-row-hover;
      color: #e5e5e5;
    }

    &-item {
      text-align: left;
      &.email {
        max-width: 6rem;
      }

      &.firstName,
      &.lastName {
        max-width: 3rem;
      }

      &.timezone {
        max-width: 5rem;
      }

      &.job {
        min-width: 4rem;
      }

      &.mobile {
        width: 3rem;
      }

      &.membertags,
      &.viewertags {
        max-width: 8rem;
      }

      &.gender {
        text-align: center;
      }

      &.userVisible {
        min-width: 6rem;
      }
    }

    &-checkbox {
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.125rem 0.3rem;
      border: $button-border;
      &-checked {
        background-color: $color-box-silver;
      }
    }
  }
}

.show {
  visibility: visible;
}

.up {
  transform: rotate(180deg);
}
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter {
  transform: translateY(-10px);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
