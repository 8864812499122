<template lang="pug">
    div(class="sz-AssessmentHistory")
        div(class="sz-AssessmentHistory-left")
            div(class="sz-AssessmentHistory-left-section")
                JumpToAssessment()
            div(class="sz-AssessmentHistory-left-section")
                AssessmentHistoryFilter()
        div(class="sz-AssessmentHistory-right")
            AssessmentHistoryTable()
</template>

<script>
    import AssessmentHistoryTable from './AssessmentHistory/AssessmentHistoryTable'
    import JumpToAssessment from './AssessmentHistory/JumpToAssessment'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import AssessmentHistoryFilter from './AssessmentHistory/AssessmentHistoryFilter'

    export default {
        name: 'AssessmentHistory',

        components: {
           AssessmentHistoryTable,
           JumpToAssessment,
           LoadingSpinner,
           AssessmentHistoryFilter,
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-AssessmentHistory {
        display: flex;
        flex-direction: row;

         &-right {
            margin-left: 1rem;
            width: 80vw;
        }

        &-left {
            width: 20vw;

            &-section {
                padding-bottom: 1.5rem;
            }
        }
    }
</style>
