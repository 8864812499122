<template lang="pug">
    div(class="sz-ProfileSettings")
        div(class="sz-ProfileSettings-sectionTitle") {{ $t(`management.profileDashboard.header`) }}
        div(
            v-if="!loadingPersonalDetails",
            class="sz-ProfileSettings-sectionSettings")
            div(class="sz-ProfileSettings-sectionSettings-input")
                FormSectionInput(
                    :title="$t(`management.profileDashboard.email`)",
                    :defaultInput="email",
                    :disableInput="changeableInputs.email",
                    :useInputTag="changeableInputs.email",
                    textSize="noAllCaps")
            div(class="sz-ProfileSettings-sectionSettings-input")
                FormSectionInput(
                    :title="$t(`management.profileDashboard.firstName`)",
                    :defaultInput="firstName",
                    :disableInput="changeableInputs.firstName",
                    :useInputTag="changeableInputs.firstName",
                    textSize="noAllCaps")
            div(class="sz-ProfileSettings-sectionSettings-input")
                FormSectionInput(
                    :title="$t(`management.profileDashboard.lastName`)",
                    :defaultInput="lastName",
                    :disableInput="changeableInputs.lastName",
                    :useInputTag="changeableInputs.lastName",
                    textSize="noAllCaps")
            div(class="sz-ProfileSettings-sectionSettings-input")
                FormSectionInput(
                    :title="$t(`management.profileDashboard.gender`)",
                    :defaultInput="getGender",
                    :disableInput="changeableInputs.gender",
                    :useInputTag="changeableInputs.gender",
                    textSize="noAllCaps")
            div(class="sz-ProfileSettings-sectionSettings-input")
                FormSectionInput(
                    :title="$t(`management.profileDashboard.mobile`)",
                    :defaultInput="mobile",
                    :disableInput="changeableInputs.mobile",
                    :useInputTag="changeableInputs.mobile",
                    textSize="noAllCaps")
            div(class="sz-ProfileSettings-sectionSettings-toggle")
                FormSectionToggle(
                    :title="$t(`management.profileDashboard.userVisible`)",
                    :defaultChecked="userVisible",
                    :options="anonymousModeOptions",
                    :tooltip="$t('management.profileDashboard.userVisibleText')",
                    @changed="updateUserVisible")
        div(
            v-if="loadingPersonalDetails || savingPersonalDetails",
            class="sz-ProfileSettings-sectionSettings-loading")
            LoadingSpinner(color="light")
</template>

<script>
import FormSectionInput from 'components/Shared/FormSectionInput'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import formValidator from 'mixins/formValidator'
import constructModalSettings from 'mixins/modalSettings'
import { mapGetters } from 'vuex'
import constants from 'helpers/constants'
import LoadingSpinner from '../Shared/LoadingSpinner'

export default {
  name: 'ProfileSettings',

  components: {
    LoadingSpinner,
    FormSectionInput,
    FormSectionToggle,
  },

  mixins: [formValidator, constructModalSettings],

  data() {
    return {
      authClient: null,
      newUserVisible: null,
      showConfirmModal: true,
    }
  },

  computed: {
    ...mapGetters([
      'email',
      'firstName',
      'lastName',
      'gender',
      'mobile',
      'userVisible',
      'companyAnon',
      'customerAnon',
      'loadingPersonalDetails',
      'savingPersonalDetails',
      'saveStatus',
      'currentCompany',
    ]),

    getGender() {
      return constants.GENDER_NAME_API_MAP[this.gender]
    },

    changeableInputs() {
      return {
        firstName: true,
        lastName: true,
        gender: true,
        mobile: true,
        email: true,
      }
    },

    anonymousModeOptions() {
      return [this.$t(`management.anonymous`), this.$t(`management.visible`)]
    },
  },

  watch: {
    showConfirmModal: {
      handler: function () {
        if (!this.showConfirmModal) {
          this.submit()
        }
      },
    },
  },

  mounted() {
    if (!this.email) {
      this.$store.dispatch('getPersonalDetails')
    }

    EventBus.$on('USER_CONFIRMED', () => {
      this.showConfirmModal = false
    })

    EventBus.$on('USER_CANCELLED', () => {
      this.showConfirmModal = true
    })
  },

  methods: {
    async updateUserVisible(newUserVisible) {
      try {
        await this.$store.dispatch('saveNewUserVisibility', { userVisible: newUserVisible })
        this.newUserVisible = newUserVisible
      } catch (e) {
        this.failModal(
          this.$t('pleaseTryAgain'),
          this.$t('somethingWentWrong'),
          () => (this.showConfirmModal = true)
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

.sz-ProfileSettings {
  position: relative;
  height: 100%;
  overflow-y: hidden;

  &-sectionSettings {
    padding-left: 2rem;

    &-input {
      min-width: 20rem;
      padding-bottom: 2rem;
      @extend %font-topbar-heading;

      &-select {
        color: $color-table-font-black;
      }
    }

    &-toggle {
      @extend %font-topbar-heading;
      font-size: 12px;
      color: $color-white;
      padding: 0.3rem 0;
    }

    &-info {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 1rem;
    }

    &-loading {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: lighten($color-body-bg, 10%);
      opacity: 0.5;
      filter: blur(1px);
      z-index: $layer-modal-loader-z-index;
    }
  }
}
</style>
