<template lang="pug">
    div(class="sz-CompanySettings")
        div(class="sz-CompanySettings-options")
            div(class="sz-CompanySettings-options-header")
            div(class="sz-CompanySettings-options-buttons")
                div(
                    @click="openManageUsers",
                    class="sz-CompanySettings-options-buttons-button",
                    :class="`sz-CompanySettings-options-buttons-button`+ (isManageUsers ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="user-filled",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-CompanySettings-options-buttons-button-icon")
                    div(class="sz-CompanySettings-options-buttons-button-text") {{ $t(`management.companyDashboard.manageUsers`) }}
                div(
                    @click.stop="openManageOCT",
                    class="sz-CompanySettings-options-buttons-button",
                    :class="`sz-CompanySettings-options-buttons-button`+ (isManageOCT ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="tag-filled",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-CompanySettings-options-buttons-button-icon")
                    div(class="sz-CompanySettings-options-buttons-button-text") {{ $t(`management.companyDashboard.manageOCT`) }}
                div(
                    @click.stop="openAnonymousMode",
                    class="sz-CompanySettings-options-buttons-button",
                    :class="`sz-CompanySettings-options-buttons-button`+ (isAnonymousMode ? '-selected' : 'notSelected')")
                    SvgIcon(
                        icon="anonymous-filled",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-CompanySettings-options-buttons-button-icon")
                    div(class="sz-CompanySettings-options-buttons-button-text") {{ $t(`management.companyDashboard.anonymousMode`) }}
                div(
                    v-if="isManageUsers",
                    class="sz-CompanySettings-options-buttons-divider")
                transition(name="slide")
                    div(
                        v-if="isManageUsers",
                        class="sz-CompanySettings-options-buttons-manageUsers")
                        div(
                            @click.stop="openBulkUploadModal",
                            class="sz-CompanySettings-options-buttons-button sz-CompanySettings-options-buttons-button-bulk")
                            SvgIcon(
                                icon="groupAdd",
                                width="1.5rem",
                                height="1.5rem",
                                class="sz-CompanySettings-options-buttons-button-icon")
                            div(class="sz-CompanySettings-options-buttons-button-text") {{ $t(`management.companyDashboard.bulkUploadUsers.bulkUploadHeader`) }}
                        div(
                            @click.stop="openNewUserModal",
                            class="sz-CompanySettings-options-buttons-button")
                            SvgIcon(
                                icon="indivAdd",
                                width="1.25rem",
                                height="1.25rem",
                                class="sz-CompanySettings-options-buttons-button-icon")
                            div(class="sz-CompanySettings-options-buttons-button-text") {{ $t(`management.companyDashboard.newUser`) }}
            div(class="sz-CompanySettings-options-divider")
        component(
            :is="selectedOption",
            class="sz-CompanySettings-manageSection")
</template>

<script>
    import modalSettings from 'mixins/modalSettings'
    import constants from 'helpers/constants'
    import UserTable from 'components/ManagementDashboard/CompanyManagement/UserTable'
    import OctManage from 'components/ManagementDashboard/CompanyManagement/OctManage'
    import CompanyAnonymousMode from 'components/ManagementDashboard/CompanyManagement/CompanyAnonymousMode'
    import { mapGetters } from 'vuex'
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: "CompanySettings",

        components: {
            SvgIcon,
            UserTable,
            OctManage,
            CompanyAnonymousMode,
        },

        mixins: [
            modalSettings,
        ],

        data () {
            return {
                selectedOption: constants.COMPANY_SETTINGS.EDIT.USERS,
            }
        },

        computed: {
            ...mapGetters([
                'currentCompany',
                'allCompanyTags',
                'allCompanySKUs',
            ]),

            isManageUsers () {
                return this.selectedOption === constants.COMPANY_SETTINGS.EDIT.USERS
            },

            isManageOCT () {
                return this.selectedOption === constants.COMPANY_SETTINGS.EDIT.OCT
            },

            isAnonymousMode () {
                return this.selectedOption === constants.COMPANY_SETTINGS.EDIT.ANONYMOUS_MODE
            },
        },

        mounted () {
            if (this.allCompanyTags.length === 0) {
                this.$store.dispatch('getAllCompanyTags')
            }

            if (this.allCompanySKUs.length === 0) {
                this.$store.dispatch('getAllCompanySKUs')
            }

            this.$store.dispatch('getCompanyInfo')
        },

        methods: {
            openBulkUploadModal () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_BULK_UPLOAD,
                    false,
                    {},
                    true,
                    true,
                    )
                )
            },

            openNewUserModal () {
                this.$store.dispatch("showModal", this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_USER,
                    false,
                    {
                        user: {
                            userID: null,
                            email: null,
                            job: null,
                            jobCode: null,
                            firstName: null,
                            lastName: null,
                            gender: null,
                            mobile: null,
                            timezone: null,
                            tags: {
                                owner: [],
                                holder: [],
                            },
                            sku: null,
                            anon: false,
                        },

                        newUser: true,
                    },
                    true,
                    true,
                    )
                )
            },

            openManageUsers () {
                this.selectedOption = constants.COMPANY_SETTINGS.EDIT.USERS
            },

            openManageOCT () {
                this.selectedOption = constants.COMPANY_SETTINGS.EDIT.OCT
            },

            openAnonymousMode () {
                this.selectedOption = constants.COMPANY_SETTINGS.EDIT.ANONYMOUS_MODE
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-CompanySettings {
        display: flex;
        flex-direction: column;

        &-options {
            &-header {
                margin-left: 4rem;
                display: flex;
            }
            &-buttons {
                @include scrollbar-widget;
                padding-bottom: 0.5rem;
                height: 4rem;
                margin: 0 4rem;
                color: $color-table-font-black;
                border-radius: 3px;
                max-width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                &-divider {
                    margin-left: 1.8rem;
                    margin-right: 0.5rem;
                    border-left: 1.5px solid grey;
                    opacity: 0.5;
                    height: 2rem;
                }

                &-manageUsers {
                    display: flex;
                    align-items: center;
                }

                &-button {
                    @extend %button-layout;
                    @extend %font-heading;
                    padding: 0.5rem 1rem;
                    font-size: 12px;
                    background-color: transparent;
                    border: none;
                    color: white;
                    display: flex;
                    align-items: center;

                    &-bulk {
                        margin-top: 0;
                    }

                    &-icon {
                        margin-right: 0.5rem;
                        margin-top: 0.2rem;
                    }

                    &:hover {
                        background-color: $color-lifebooster-light-green ;
                    }

                    &-selected {
                        background-color: $color-lifebooster-light-green ;
                    }
                }
            }

            &-divider {
                border: 0.5px solid #1A8F99;
                margin-bottom: 1rem;
                margin-left: 4rem;
                margin-right: 4rem;
                opacity: 0.4;
            }
        }

        &-manageSection {
            margin: 0 4rem;
            display: flex;

            &-header {
                display: flex;
            }
        }
    }
    .slide-enter-active {
        transition: all .3s ease;
    }
    .slide-leave-active {
        transition: all .2s ease;
    }
    .slide-enter {
        transform: translateX(-10px);
        opacity: 0;
    }
    .slide-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }

</style>
