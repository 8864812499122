<template lang="pug">
    form(class="sz-AssessmentFilters")
        div(class="sz-AssessmentFilters-body")
            div(class="sz-AssessmentFilters-body-title") {{ "Filter By" }}
            div(class="sz-AssessmentFilters-body-section")
                FormSectionTagSelection(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.orgChartTags`)",
                    :options="orgChartTagOptions",
                    :defaultSelectedTags="defaultOrgChartTags",
                    @updateSelectedTags="updateSelectedOrgChartTags")
            div(class="sz-AssessmentFilters-body-section")
                FormSectionTagSelection(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.jobs`)",
                    :options="jobOptions",
                    :defaultSelectedTags="defaultJobs",
                    @updateSelectedTags="updateSelectedJobs")
            div(class="sz-AssessmentFilters-body-section")
                FormSectionTagSelection(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.genders`)",
                    :options="genderOptions",
                    :defaultSelectedTags="defaultGenders",
                    @updateSelectedTags="updateSelectedGenders")
            div(class="sz-AssessmentFilters-body-section")
                FormSectionTagSelection(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.names`)",
                    :options="nameOptions",
                    :defaultSelectedTags="defaultNames",
                    @updateSelectedTags="updateSelectedNames")
            div(class="sz-AssessmentFilters-body-section-preset")
                FormSectionDropdownSingleSelect(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.presets`)",
                    :defaultSelectedOption="defaultPreset",
                    :options="presetOptions",
                    :placeholder="presetPlaceholder",
                    @updateSelectedOption="updateSelectedPreset",
                    ref="presetPicker",
                    class="sz-FormEditWidget-row-date-presets")
            div(class="sz-AssessmentFilters-body-section-time")
                FormSectionDateRangePicker(
                    class="sz-AssessmentFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.interval`)",
                    :defaultSelectedInterval="defaultInterval",
                    @updateSelectedDateRange="updateSelectedInterval",
                    ref="datePicker")
            div(class="sz-AssessmentFilters-body-footer")
                div(
                    @click="resetFilters",
                    class="sz-AssessmentFilters-body-footer-buttons \
                        sz-AssessmentFilters-body-footer-buttons-reset") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.reset`)}}
                div(
                    type="submit",
                    @click="applyFilters",
                    class="sz-AssessmentFilters-body-footer-buttons \
                        sz-AssessmentFilters-body-footer-buttons-apply") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.apply`)}}

</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
    import FormSectionDropdownSingleSelect from "components/Shared/FormSectionDropdownSingleSelect"
    import FormSectionDateRangePicker from 'components/Shared/FormSectionDateRangePicker'
    import EventBus from 'src/eventBus'
    import { splitFirstNameLastName } from 'helpers/utilities'

    export default {
        name: "AssessmentFilters",

        components: {
            FormSectionTagSelection,
            FormSectionDropdownSingleSelect,
            FormSectionDateRangePicker,
        },

        props: {
            viewerOCTs: {
                type: Array,
                required: true,
                default: () => [],
            },
        },

        data () {
            return {
                selectedOrgChartTags: [],
                selectedJobs: [],
                selectedGenders: [],
                selectedUserIDs: [],
                interval: '',
            }
        },

        computed: {
            ...mapGetters([
                'allCompanyTags',
                'jobs',
                'allCompanyUsers',
                'assessmentListFilters',
                'userID',
            ]),

            defaultOrgChartTags () {
                return (this.assessmentListFilters.orgChartTags || [])
                    .map(tagID => this.allCompanyTags
                        .find(tag => tag.tagID === tagID).name)
            },

            defaultJobs () {
                return this.assessmentListFilters.jobs || []
            },

            defaultGenders () {
                return this.assessmentListFilters.genders || []
            },

            defaultNames () {
                return (this.assessmentListFilters.userIDs || [])
                    .map(userID => {
                        let user = this.allCompanyUsers.find(user => user.userID === userID)
                        return `${user.firstName.trim()} ${user.lastName.trim()}`
                    })
            },

            defaultPreset () {
                let interval = this.assessmentListFilters.interval || ''
                let intervalIsInPresetMap = Object.values(constants.DATE_PRESET_INTERVAL_MAP).includes(interval)
                return intervalIsInPresetMap ? constants.INTERVAL_DATE_PRESET_MAP[interval] : interval
            },

            defaultInterval () {
                return this.assessmentListFilters.interval || ''
            },

            orgChartTagOptions () {
                let viewableTagNames = this.viewerOCTs.map((tag) => { return tag.tagID })
                let viewableTags = this.allCompanyTags.filter((tag) => viewableTagNames.includes(tag.tagID))

                return viewableTags.map(tag => tag.name)
            },

            jobOptions () {
                return this.jobs.map(job => job.name)
            },

            genderOptions () {
                return Object.values(constants.GENDER_NAME_API_MAP)
            },

            nameOptions () {
                return this.allCompanyUsers
                    .filter(user => (user.firstName !== undefined && user.lastName !== undefined))
                        .map(user => {
                              return `${user.firstName} ${user.lastName}`
                        })
            },

            presetOptions () {
                let presetOptions =  Object.values(constants.EXPORT_DATE_PRESETS).map((preset) => {
                    return {
                        name: this.$t(`modals.modalEditWidget.presetTypes.${preset}`),
                        type: preset,
                    }
                })

                return presetOptions
            },

            presetPlaceholder () {
                return this.$t(`modals.modalEditWidget.presetPlaceholder`)
            },

            intervalIsPreset () {
                return this.interval ? (Object.values(constants.DATE_PRESET_INTERVAL_MAP)).includes(this.interval)
                                     || Object.values(constants.EXPORT_DATE_PRESETS).includes(this.interval)
                                     : false
            },

            currentFilters () {
                return {
                  orgChartTags: this.selectedOrgChartTags,
                  jobs: this.selectedJobs,
                  genders: this.selectedGenders,
                  userIDs: this.selectedUserIDs,
                  interval: this.intervalIsPreset ? constants.DATE_PRESET_INTERVAL_MAP[this.interval] : this.interval,
                }
            },
        },

        watch: {
            interval () {
                this.intervalIsPreset ?  this.clearDatePicker() : this.clearPreset()
            },
        },

        methods: {
            updateSelectedOrgChartTags(newTags) {
                if (newTags && newTags.length) {
                    newTags = newTags
                        .map(tagName => this.allCompanyTags
                            .find(tag => tag.name === tagName).tagID)
                }

                this.selectedOrgChartTags = newTags
            },

            updateSelectedJobs(newJobs) {
                this.selectedJobs = newJobs
            },

            updateSelectedGenders(newGenders) {
                this.selectedGenders = newGenders.map((gender) => {
                    return constants.GENDER_API_NAME_MAP[gender]
                })
            },

            updateSelectedNames(newNames) {
                if (newNames && newNames.length) {
                    newNames = newNames
                        .map(name => {
                            let splitName = splitFirstNameLastName(name)
                            return this.allCompanyUsers.find(user => (user.firstName.trim() === splitName.firstName && user.lastName.trim() === splitName.lastName)).userID
                        })
                }
                this.selectedUserIDs = newNames
            },

            updateSelectedPreset(newPreset) {
                this.interval = newPreset
            },

            updateSelectedInterval (newInterval) {
                this.interval = newInterval
            },

            clearPreset () {
                this.$refs.presetPicker.clearSelectedOption()
            },

            clearDatePicker () {
                this.$refs.datePicker.clearDatePickers()
            },

            async resetFilters () {
                this.selectedOrgChartTags = []
                this.selectedJobs = []
                this.selectedGenders = []
                this.selectedUserIDs = []
                this.interval = ''

                await Promise.all([
                    this.$store.dispatch('getAssessmentsFromCompany', this.currentFilters),
                    this.$store.dispatch('updateAssessmentListFilters', this.currentFilters),
                    EventBus.$emit('CLEAR_SELECTED_TAGS'),
                ])

                this.publishUpdateEvent()
            },

            async applyFilters () {
                await Promise.all([
                    this.$store.dispatch('getAssessmentsFromCompany', this.currentFilters),
                    this.$store.dispatch('updateAssessmentListFilters', this.currentFilters),
                ])

                this.publishUpdateEvent()
            },

            publishUpdateEvent () {
                EventBus.$emit('ASSESSMENT_FILTERS_UPDATED')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-AssessmentFilters {
        position: relative;
        width: inherit;
        height: 100%;

        &-body {
            background-color: $color-tooltip-dark;
            max-height: 61.5vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem 0 1rem 0;
            overflow-y: scroll;
            overflow-x: hidden;
            @include scrollbar-widget;

            &-title {
                align-self: flex-start;
                padding: 0rem 1rem 0.5rem 1rem;
                @extend %font-heading;
                font-size: 14px;
            }

            &-section {
                padding: 0.25rem 1rem 0.25rem 1rem;

                &-preset {
                    padding: 0.5rem 1rem 0.5rem 1rem;
                    width: 90.75%;
                }

                &-time {
                    padding: 0.5rem 1rem 0rem 1rem;
                    width: 92%;
                }

                &-select {
                    font-size: 12px;
                }
            }

            &-footer {
                padding-top: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &-buttons {
                    cursor: pointer;
                    @extend %font-topbar-heading;

                    &-reset {
                        color: $color-white;
                        margin: 0 1rem;
                        font-size: 12px;
                    }

                    &-apply {
                        @extend %button-layout;
                        background-color: $color-lifebooster-light-green;
                        padding: 0.5rem 1rem;
                        border-radius: 0;
                    }
                }
            }
        }
    }
</style>
