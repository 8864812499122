<template lang="pug">
    div(class="sz-OctManage")
        div(class= "sz-OctManage-table")
            div(class="sz-OctManage-table-optionsBar")
                div(
                    @click.stop="openNewTag",
                    class="sz-OctManage-table-optionsBar-option") {{ $t(`management.companyDashboard.tagManagement.addTag`) }}
                div(class="sz-OctManage-table-optionsBar-search")
                    input(
                        type="text",
                        v-model="search",
                        placeholder="Type here to search",
                        class="sz-OctManage-table-optionsBar-search-input")
                    SvgIcon(
                        icon="search",
                        width="0.5rem",
                        height="0.5rem",
                        class="sz-OctManage-table-optionsBar-search-icon")
            div(class="sz-OctManage-table-body")
                div(
                    @click="setCurrentSort()",
                    class="sz-OctManage-table-header")
                    div(class="sz-OctManage-table-header-title") {{ $t(`management.companyDashboard.tagManagement.name`) }}
                    div(class="sz-OctManage-table-header-arrowWrapper")
                        SvgIcon(
                            icon="arrow",
                            width="10",
                            height="10",
                            class="sz-OctManage-table-header-arrow",
                            :class="'sz-OctManage-table-header-arrow-' + currentSortDirection")
                div(
                    v-if="!allCompanyTags.length",
                    class="sz-OctManage-table-empty") {{ $t(`management.companyDashboard.tagManagement.noTags`) }}
                div(
                    v-else,
                    class="sz-OctManage-table-octs")
                    div(
                        class="sz-OctManage-table-octs-oct",
                        :class="`sz-OctManage-table-octs-oct` + (isSelectOCT(oct) ? '-selected ': 'notSelected')",
                        v-for="oct of allOcts",
                        :key="oct.tagID",
                        @click.stop="openEditTag(oct)") {{ oct.name }}
            div(
                v-if="updatingTag || companyTagListLoading",
                class="sz-OctManage-table-loading")
                LoadingSpinner
        div(
            v-if="showEdit",
            class="sz-OctManage-edit")
            EditOct(
                :title="title",
                @closeEdit="closeEdit",
                :tag="selectedOct",
                :key="editKey")

</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import SvgIcon from 'components/Shared/SvgIcon'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import EditOct from './EditOct'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'

    export default {

        name: "OctManage",

        components: {
            SvgIcon,
            FormSectionInput,
            EditOct,
            LoadingSpinner,
        },

        data () {
            return {
                showEdit: false,
                selectedOCT: {},
                editTitle: '',
                search: '',
                currentSortDirection: 'down',
            }
        },

        computed: {
            ...mapGetters([
                'allCompanyTags',
                'updatingTag',
                'companyTagListLoading',
            ]),

            title () {
                return this.editTitle || ''

            },

            selectedOct () {
                return this.selectedOCT || {}
            },

            allOcts () {
                return this.allCompanyTags
                    .filter((tag) => {
                        return ((tag.name.toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1))
                    }).sort((a,b) => {
                        let direction = 1
                        if (this.currentSortDirection === 'down') direction = -1
                        if (a.name < b.name) return -1 * direction
                        if (a.name > b.name) return direction
                        return 0
                    })
            },

            editKey () {
                return this.selectedOCT.tagID || ''
            },

            isSelectNew () {
                return this.selectedOCT === null
            },
        },

        methods: {
            openNewTag () {
                this.showEdit = true
                this.editTitle = this.$t(`management.companyDashboard.tagManagement.addTag`)
                this.selectedOCT = {}
            },

            closeEdit () {
                this.showEdit = false
                this.selectedOCT = {}
                this.editTitle = ''
            },

            openEditTag (oct) {
                this.selectedOCT = oct
                this.showEdit = true
                this.editTitle = this.$t(`management.companyDashboard.tagManagement.editTag`)
            },

            isSelectOCT(oct) {
                return oct === this.selectedOCT
            },

            setCurrentSort () {
                this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-OctManage {
        display: flex;
        flex-direction: row;
        color: $color-text;

        &-table {
            width: 35vw;
            min-width: 18rem;
            margin-right: 1rem;
            position: relative;

            &-loading {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: lighten($color-body-bg, 10%);
                opacity: 0.5;
                filter: blur(1px);
                z-index: $layer-modal-loader-z-index;
            }

            &-optionsBar {
                width: 100%;
                display: flex;
                background-color: $color-header-bar-backround;
                align-items: center;
                padding: 0.5rem 0;
                font-size: 13px;
                @extend %font-heading;
                @include search-bar;

                &-option {
                    margin-left: 1rem;
                    cursor: pointer;
                }

                &-search {
                    margin-left: auto;
                    margin-right: 1rem;
                    width: 10rem;
                }
            }

            &-header {
                @extend %font-heading;
                cursor: pointer;
                user-select: none;
                padding-top: 0.5rem;
                color: $color-text;
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                display: inline-flex;
                justify-content: center;
                margin-left: 1.25rem;

                &-arrow {
                    padding: 0 0.05rem;
                    margin-top: 0.75rem;
                    margin-left: 0.5rem;

                    &-up {
                        transform: rotate(180deg)
                    }
                }
            }

            &-body {
                background-color: $color-box-background;
                height: 60vh;
                overflow: auto;
                @include scrollbar-widget;
            }

            &-octs {
                width: 100%;
                font-size: 0.875rem;
                color: $color-unselected-text;

                &-oct {
                    cursor: pointer;
                    padding: 0.5rem;
                    &:hover {
                        background: $color-table-row-hover;
                        color: $color-text;
                    }

                    &-selected {
                        background: $color-table-row-hover;
                        color: $color-text;
                    }
                }
            }
        }
    }


</style>
