<template lang="pug">
    div(
        v-click-away="close",
        class="sz-DropdownEditUser",
        :class="'sz-DropdownEditUser-' + (individualOption ? 'individualOption' : 'bulkOption')")
        ul(class="sz-DropdownEditUser-options")
            div(
                v-if="users.length > 1")
                li(
                    v-for=" option in bulkOptions",
                    @click="openModal(option, users)",
                    class="sz-DropdownEditUser-option") {{ $t(`management.companyDashboard.editUserOption.${ option }`) }}
            li(
                v-else-if="users.length === 0",
                class="sz-DropdownEditUser-option") {{ $t(`management.companyDashboard.editUserOption.noUserSelected`)}}
</template>

<script>
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import { ClickAway } from 'directives/clickAway'
    export default {
        name: 'DropdownEditUser',

        directives: {
            ClickAway,
        },

        mixins: [
            modalSettings,
        ],

        props: {
            users: {
                required: true,
                type: Array,
            },
        },

        computed: {
            bulkOptions () {
                return constants.USER_MANAGE_EDIT_OPTIONS
            },

            individualOption () {
                return this.users.length === 1
            },
        },

        methods: {
            close () {
                this.$emit('closeEditDropdown')
            },
            
            openModal (option, users) {
                const modalName = `MODAL_EDIT_USER_${option.toUpperCase()}`
                this.$store.dispatch('showModal', this.constructModalSettings(
                    constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_BULK,
                    false,
                    { users, option },
                    true,
                    true)
                )
            },
        },
        
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    li {
        margin: 0;
    }
    .sz-DropdownEditUser {
        @extend %font-heading;
        font-size: 0.75rem;
        position: absolute;
        left: 4.75rem;
        top: 13.5rem;
        z-index: 1;

        &-individualOption {
            box-shadow: none;
            background-color: transparent;
        }
        &-bulkOption {
            background-color: $color-dropdown-background;
            box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.48);
        }
        &-options {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
        }
        &-option {
            display: flex;
            justify-content: flex-start;
            width: 8rem;
            padding: 0.35rem 0.75rem;
            cursor: pointer;
            &:hover {
                background-color:$color-lifebooster-medium-green;
            }
        }
    }
</style>