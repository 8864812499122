<template lang="html">
  <div class="sz-ApplyDefaultAssessmentTags">
    <div class="sz-ApplyDefaultAssessmentTags-left">
      <DefaultAssessmentTagsFilters />
    </div>
    <div class="sz-ApplyDefaultAssessmentTags-right">
      <DefaultAssessmentTagsTable 
        :viewer-o-c-ts="reqUserViewerTags"
      />
    </div>
  </div>
</template>

<script>
    import DefaultAssessmentTagsTable from './ApplyDefaultAssessmentTags/DefaultAssessmentTagsTable'
    import DefaultAssessmentTagsFilters from './ApplyDefaultAssessmentTags/DefaultAssessmentTagsFilters'
    import { mapGetters } from 'vuex'

    export default {
        name: 'ApplyDefaultAssessmentTags',

        components: {
            DefaultAssessmentTagsTable,
            DefaultAssessmentTagsFilters,
        },

        computed: {
            ...mapGetters([
                'allAssessmentTags',
                'jobs',
                'allCompanyUsers',
                'applyDefaultAssessmentTagsUsers',
                'applyDefaultAssessmentTagsFilters',
                'userID',
                'reqUserProfile',
            ]),

            reqUserViewerTags () {
                return this.reqUserProfile.viewertags
            },
        },

        mounted () {
            if (!this.reqUserProfile) {
                this.$store.dispatch('getUserCompleteProfileByID', this.userID)
            }

            if (!this.allAssessmentTags || this.allAssessmentTags.length === 0) {
                this.$store.dispatch('getAllAssessmentTags')
            }

            if (!this.allCompanyTags || this.allCompanyTags.length === 0) {
                this.$store.dispatch('getAllCompanyTags')
            }

            if (!this.jobs || this.jobs.length === 0) {
                this.$store.dispatch('updateJobTypes')
            }

            if (!this.applyDefaultAssessmentTagsUsers || this.applyDefaultAssessmentTagsUsers.length === 0) {
                if (Object.keys(this.applyDefaultAssessmentTagsFilters).length > 0) {
                    this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', this.applyDefaultAssessmentTagsFilters)
                } else {
                    this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', {})
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ApplyDefaultAssessmentTags {
        display: flex;
        flex-direction: row;

        &-left {
            padding-right: 2rem;
            width: 18vw;
        }

        &-right {
            width: 82vw;
        }
    }
</style>
