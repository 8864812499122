/**
 * Returns a list of strings that are an intersection of a worker's member tags and a logged in user's viewable tags
 * representing an ID of an OCT
 *
 * @params memberTags A list of strings representing an OCT ID
 * @params viewerTags A list of OCTs that the logged in user has access to as { name: string, id: string }[]
 *
 * @returns A list of strings representing the ID of an OCT
 */
export function getOCTIntersection(memberTags, viewerTags) {
  if (!memberTags || !viewerTags) {
    return []
  }

  let viewableTagIDs = viewerTags.map((tag) => {
    return tag.tagID
  })

  return memberTags.filter((tag) => {
    return viewableTagIDs.includes(tag)
  })
}
