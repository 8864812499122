<template lang="pug">
    div(class="sz-AssessmentSettings")
        div(class="sz-AssessmentSettings-options")
            div(class="sz-AssessmentSettings-options-header")
            div(class="sz-AssessmentSettings-options-buttons")
                div(
                    @click="openAssessmentProgress",
                    class="sz-AssessmentSettings-options-buttons-button",
                    :class="`sz-AssessmentSettings-options-buttons-button`+ (isAssessmentProgress ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="hourglass",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-AssessmentSettings-options-buttons-button-icon")
                    div(class="sz-AssessmentSettings-options-buttons-button-text") {{ $t(`management.assessmentDashboard.assessmentHistory`) }}
                div(
                    @click.stop="openManageAssessmentTags",
                    class="sz-AssessmentSettings-options-buttons-button",
                    :class="`sz-AssessmentSettings-options-buttons-button`+ (isManageAssessmentTags ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="tag-filled",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-AssessmentSettings-options-buttons-button-icon")
                    div(class="sz-AssessmentSettings-options-buttons-button-text") {{ $t(`management.assessmentDashboard.manageAssessmentTags.button`) }}
                div(
                    @click.stop="openAssignAssessmentTags",
                    class="sz-AssessmentSettings-options-buttons-button",
                    :class="`sz-AssessmentSettings-options-buttons-button`+ (isAssignAssessmentTags ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="edit",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-AssessmentSettings-options-buttons-button-icon")
                    div(class="sz-AssessmentSettings-options-buttons-button-text") {{ $t(`management.assessmentDashboard.assignAssessmentTags.button`) }}
                div(
                    @click.stop="openApplyDefaultTags",
                    class="sz-AssessmentSettings-options-buttons-button",
                    :class="`sz-AssessmentSettings-options-buttons-button`+ (isApplyDefaultTags ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="indivAdd",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-AssessmentSettings-options-buttons-button-icon")
                    div(class="sz-AssessmentSettings-options-buttons-button-text") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.button`) }}
                div(
                    v-if="isLBAssessor",
                    @click.stop="openExcludeAssessments",
                    class="sz-AssessmentSettings-options-buttons-button",
                    :class="`sz-AssessmentSettings-options-buttons-button`+ (isExcludeAssessments ? '-selected ': 'notSelected')")
                    SvgIcon(
                        icon="visibilityOff",
                        width="1.35rem",
                        height="1.35rem",
                        class="sz-AssessmentSettings-options-buttons-button-icon")
                    div(class="sz-AssessmentSettings-options-buttons-button-text") {{ $t(`management.assessmentDashboard.excludeAssessments.button`) }}
            div(class="sz-AssessmentSettings-options-divider")
        component(
            :is="selectedOption",
            class="sz-AssessmentSettings-manageSection")
</template>

<script>
    import constants from 'helpers/constants'
    import AssessmentHistory from 'components/ManagementDashboard/AssessmentManagement/AssessmentHistory'
    import ManageAssessmentTags from 'components/ManagementDashboard/AssessmentManagement/ManageAssessmentTags'
    import AssignAssessmentTags from 'components/ManagementDashboard/AssessmentManagement/AssignAssessmentTags'
    import ExcludeAssessments from 'components/ManagementDashboard/AssessmentManagement/ExcludeAssessments'
    import ApplyDefaultAssessmentTags from 'components/ManagementDashboard/AssessmentManagement/ApplyDefaultAssessmentTags'
    import SvgIcon from 'components/Shared/SvgIcon'
    import { mapGetters } from 'vuex'
    import { userIsLBAssessor } from 'helpers/permissionsHelper'
    import { getInstance } from 'auth/index'

    export default {
        name: "AssessmentSettings",

        components: {
            SvgIcon,
            AssessmentHistory,
            ManageAssessmentTags,
            AssignAssessmentTags,
            ExcludeAssessments,
            ApplyDefaultAssessmentTags,
        },

        data () {
            return {
                selectedOption: constants.ASSESSMENT_SETTINGS.HISTORY,
            }
        },

        computed: {
          ...mapGetters([
                'currentCompany',
            ]),

            isAssessmentProgress () {
                return this.selectedOption === constants.ASSESSMENT_SETTINGS.HISTORY
            },

            isManageAssessmentTags () {
                return this.selectedOption === constants.ASSESSMENT_SETTINGS.MANAGE_ASSESSMENT_TAGS
            },

            isAssignAssessmentTags () {
                return this.selectedOption === constants.ASSESSMENT_SETTINGS.ASSIGN_ASSESSMENT_TAGS
            },

            isApplyDefaultTags () {
                return this.selectedOption === constants.ASSESSMENT_SETTINGS.APPLY_DEFAULT_TAGS
            },

            isExcludeAssessments () {
                return this.selectedOption === constants.ASSESSMENT_SETTINGS.EXCLUDE_ASSESSMENTS
            },

            isLBAssessor () {
                return userIsLBAssessor(getInstance().user)
            },
        },

        methods: {
            openAssessmentProgress () {
                this.selectedOption = constants.ASSESSMENT_SETTINGS.HISTORY
            },

            openManageAssessmentTags () {
                this.selectedOption = constants.ASSESSMENT_SETTINGS.MANAGE_ASSESSMENT_TAGS
            },

            openAssignAssessmentTags () {
                this.selectedOption = constants.ASSESSMENT_SETTINGS.ASSIGN_ASSESSMENT_TAGS
            },

            openApplyDefaultTags () {
                this.selectedOption = constants.ASSESSMENT_SETTINGS.APPLY_DEFAULT_TAGS
            },

            openExcludeAssessments () {
                this.selectedOption = constants.ASSESSMENT_SETTINGS.EXCLUDE_ASSESSMENTS
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-AssessmentSettings {
        display: flex;
        flex-direction: column;

        &-options {
            &-header {
                margin-left: 4rem;
                display: flex;
            }
            &-buttons {
                @include scrollbar-widget;
                padding-bottom: 0.5rem;
                height: 4rem;
                margin: 0 4rem;
                color: $color-table-font-black;
                border-radius: 3px;
                max-width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                &-divider {
                    margin-left: 1.8rem;
                    margin-right: 0.5rem;
                    border-left: 1.5px solid grey;
                    opacity: 0.5;
                    height: 2rem;
                }

                &-button {
                    @extend %button-layout;
                    @extend %font-heading;
                    padding: 0.5rem 1rem;
                    font-size: 12px;
                    background-color: transparent;
                    border: none;
                    color: white;
                    display: flex;
                    align-items: center;

                    &-icon {
                        margin-right: 0.5rem;
                        margin-top: 0.2rem;
                    }

                    &:hover {
                        background-color: $color-lifebooster-light-green;
                    }

                    &-selected {
                        background-color: $color-lifebooster-light-green;
                    }
                }
            }

            &-divider {
                border: 0.5px solid $color-lifebooster-light-green;
                margin-bottom: 1rem;
                margin-left: 4rem;
                margin-right: 4rem;
                opacity: 0.4;
            }
        }

        &-manageSection {
            margin: 0 4rem;
            display: flex;

            &-header {
                display: flex;
            }
        }
    }
</style>
