<template lang="html">
  <div class="sz-AssignAssessmentTags">
    <div class="sz-AssignAssessmentTags-left">
      <AssessmentFilters 
        :viewer-o-c-ts="reqUserViewerTags"
      />
    </div>
    <div class="sz-AssignAssessmentTags-right">
      <AssessmentTable 
        :viewer-o-c-ts="reqUserViewerTags"
      />
    </div>
  </div>
</template>

<script>
    import AssessmentTable from './AssignAssessmentTags/AssessmentTable'
    import AssessmentFilters from './AssignAssessmentTags/AssessmentFilters'
    import { mapGetters } from 'vuex'

    export default {
        name: 'AssignAssessmentTags',

        components: {
            AssessmentTable,
            AssessmentFilters,
        },

        computed: {
            ...mapGetters([
                'allCompanyAssessments',
                'allAssessmentTags',
                'getAllAssessmentTags',
                'allCompanyTags',
                'getAllCompanyTags',
                'getAssessmentsFromCompany',
                'jobs',
                'updateJobTypes',
                'allCompanyUsers',
                'assessmentListFilters',
                'reqUserProfile',
                'userID',
            ]),

            reqUserViewerTags () {
                return this.reqUserProfile.viewertags
            },
        },

        mounted () {
            this.$store.dispatch('getUserCompleteProfileByID', this.userID)

            if (!this.allAssessmentTags || this.allAssessmentTags.length === 0) {
                this.$store.dispatch('getAllAssessmentTags')
            }

            if (!this.allCompanyTags || this.allCompanyTags.length === 0) {
                this.$store.dispatch('getAllCompanyTags')
            }

            if (!this.allCompanyAssessments || this.allCompanyAssessments.length === 0) {
                if (Object.keys(this.assessmentListFilters).length > 0) {
                    this.$store.dispatch('getAssessmentsFromCompany', this.assessmentListFilters)
                } else {
                    this.$store.dispatch('getAssessmentsFromCompany', {})
                }
            }

            if (!this.jobs || this.jobs.length === 0) {
                this.$store.dispatch('updateJobTypes')
            }

            if (!this.allCompanyUsers || this.allCompanyUsers.length === 0) {
                this.$store.dispatch('getAllUsersInCompany')
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-AssignAssessmentTags {
        display: flex;
        flex-direction: row;

        &-left {
            padding-right: 2rem;
            width: 18vw;
        }

        &-right {
            width: 82vw;
        }
    }
</style>
