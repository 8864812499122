<template lang="pug">
    div(class="sz-CustomerSettings")
        div(class="sz-CustomerSettings-sectionTitle") {{ $t(`management.customerDashboard.header`) }}
    
</template>

<script>
    export default {
        name: "CustomerSettings",

    }
</script>

<style lang="scss" scoped>

</style>