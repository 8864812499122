<template lang="pug">
    Layout(:showWorkerCard="showWorkerCard")
        ManagementDashboard
</template>

<script>
    import Layout from '../layouts/main'
    import ManagementDashboard from 'components/Dashboards/ManagementDashboard'
    import { userCanAccessPage } from "../../helpers/permissionsHelper"
    import { getInstance } from 'auth/index'

    import constants from 'helpers/constants'

    export default {
        name: "Settings",

        components: {
            Layout,
            ManagementDashboard,
        },

        props: {
            currentSettingsPage: {
                required: false,
                type: String,
                default: constants.SETTINGS_PAGES.PROFILE,
            },
            customer: {
                required: false,
                type: String,
                default: '',
            },
            company: {
                required: false,
                type: String,
                default: '',
            },
        },

        computed: {
            showWorkerCard () {
                return false
            },
        },


        watch: {
            currentSettingsPage () {
                this.$store.dispatch('updateCurrentSettingsPage', this.currentSettingsPage)
            },
        },

        mounted () {
            if (!userCanAccessPage(getInstance().user, this.currentSettingsPage)) {
                this.$router.push({ name: 'management', params: { currentSettingsPage: constants.SETTINGS_PAGES.PROFILE }})
            }
            this.$store.dispatch('updateCurrentSettingsPage', this.currentSettingsPage)
            if (this.customer && this.company) {
                this.$store.dispatch('updateCurrentCustomerAndCompany', { customerID: this.customer, companyID: this.company })
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>