<template lang="pug">
    div(class="sz-AssessmentHistoryFilter")
        div(class="sz-AssessmentHistoryFilter-header")
            div(class="sz-AssessmentHistoryFilter-header-title") {{ $t(`management.assessmentDashboard.assessmentHistoryFilter.title`) }}
        div(class="sz-AssessmentHistoryFilter-body")
            div(class="sz-JumpToAssessment-body-input")
                FormSectionInput(
                    class="sz-AssessmentHistoryFilter-body-input-textfield",
                    :title="$t(`management.assessmentDashboard.assessmentHistoryFilter.firstName`)",
                    textSize="content",
                    :useInputTag="true",
                    @updated="updateFirstNameFilter",
                    @enter="handleFilterformSubmit()")
            div(class="sz-JumpToAssessment-body-input")
                FormSectionInput(
                    class="sz-AssessmentHistoryFilter-body-input-textfield",
                    :title="$t(`management.assessmentDashboard.assessmentHistoryFilter.lastName`)",
                    textSize="content",
                    :useInputTag="true",
                    @updated="updateLastNameFilter",
                    @enter="handleFilterformSubmit()")
            div(class="sz-JumpToAssessment-body-input")
                FormSectionInput(
                    class="sz-AssessmentHistoryFilter-body-input-textfield",
                    :title="$t(`management.assessmentDashboard.assessmentHistoryFilter.setID`)",
                    textSize="content",
                    :useInputTag="true",
                    @updated="updateSetIDFilter",
                    @enter="handleFilterformSubmit()")
            div(class="sz-JumpToAssessment-body-input")
                 DropdownMultiSelect(
                        :options="options",
                        selectionTitle="Status",
                        showSelectedOptions=true,
                        @updateSelectedOptions="updateSelectedState",
                        class="sz-AssessmentHistoryFilter-body-input-dropdown",
                        ref="DropdownMultiSelect")
            div(class="sz-AssessmentHistoryFilter-body-footer")
                div(@click = "clearFilterForm()", class="sz-AssessmentHistoryFilter-body-footer-clear") {{ $t(`management.assessmentDashboard.assessmentHistoryTable.clear`) }}
                div(@click = "handleFilterformSubmit()", class="sz-AssessmentHistoryFilter-body-footer-filter") {{ $t(`management.assessmentDashboard.assessmentHistoryFilter.filter`) }}
</template>

<script>
    import constants from 'helpers/constants'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import { mapGetters } from 'vuex'
    import DropdownMultiSelect from 'components/Shared/DropdownMultiSelect'
    import FormSectionInput from 'components/Shared/FormSectionInput'

    export default {
        name: 'AssessmentHistoryFilter',

        components: {
            LoadingSpinner,
            DropdownMultiSelect,
            FormSectionInput,
        },

        data () {
            return {
                filterForm: {
                    firstname: "",
                    lastname: "",
                    setID: "",
                    status: [],
                },
            }
        },

        computed: {
            ...mapGetters([
                'currentCompany',
                'currentCustomer',
                'auth0User',
                'allCaptureProgress',
            ]),

            options() {
                return Object.values(constants.ASSESSMENT_PROGRESS_STATE).map(status => { return {
                    type: this.$t(`management.assessmentDashboard.assessmentHistoryFilter.status.${status}`),
                    name: this.$t(`management.assessmentDashboard.assessmentHistoryFilter.status.${status}`) }} )
            },
        },

        methods: {
            updateSelectedState(newState){
                this.filterForm.status = newState
            },

            async handleFilterformSubmit(){
                // Might be a bandage fix. The BE stores the setID as a string of hex value so it has the 0x prefix. However, the setID exposed to the user does not have 0x prefix so they will not enter setID with 0x
                if (this.filterForm.setID && this.filterForm.setID[1] != 'x') {
                    this.filterForm.setID = '0x' + this.filterForm.setID
                }
                await this.$store.dispatch('getFirstCaptureProgressPage', this.filterForm)
            },

            async clearFilterForm(){
                this.filterForm = {
                    firstname: "",
                    lastname: "",
                    setID: "",
                    status: [],
                }
                this.$refs.DropdownMultiSelect.clearSelectedOptions()
                await this.$store.dispatch('getFirstCaptureProgressPage')
            },

            updateFirstNameFilter(firstname){
                this.filterForm.firstname = firstname
            },

            updateLastNameFilter(lastname){
                this.filterForm.lastname = lastname
            },

            updateSetIDFilter(setID){
                this.filterForm.setID = setID
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-AssessmentHistoryFilter {
        position: relative;
        width: inherit;
        height: 100%;

        &-header {
            width: 100%;
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.75rem 0;
            font-size: 14px;
            @extend %font-heading;

            &-title {
                margin-left: 1rem;
            }
        }

        &-body {
            background-color: $color-tooltip-dark;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 12px;

            &-input {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-self: center;
                background-color: $color-tooltip-dark;

                &-dropdown {
                    align-items: center;
                    width: 89.25%;
                    height: 4vh;
                    padding-left: 1rem;
                    padding-bottom: 1rem;
                    z-index: 2;
                }

                &-textfield {
                    padding-left: 1rem;
                    padding-bottom: 1rem;
                    width: 94%;
                }

            }

            &-footer {
                padding-top: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &-clear {
                    color: $color-white;
                    margin: 0 1rem;
                    font-size: 12px;
                    cursor: pointer;
                    @extend %font-topbar-heading;
                }

                &-filter {
                    @extend %button-layout;
                    background-color: $color-lifebooster-light-green;
                    padding: 0.5rem 1rem;
                    border-radius: 0;
                    cursor: pointer;
                    @extend %font-topbar-heading;
                }
            }
        }
    }
</style>
