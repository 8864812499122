<template lang="pug">
    div(class="sz-ExcludeAssessments")
        div(class="sz-ExcludeAssessments-left")
            ExcludeAssessmentsFilters()
        div(class="sz-ExcludeAssessments-right")
            ExcludeAssessmentsTable()
</template>

<script>
    import ExcludeAssessmentsTable from './ExcludeAssessments/ExcludeAssessmentsTable'
    import ExcludeAssessmentsFilters from './ExcludeAssessments/ExcludeAssessmentsFilters'
    import { mapGetters } from 'vuex'
    import EventBus from 'src/eventBus'

    export default {
        name: 'ExcludeAssessments',

        components: {
            ExcludeAssessmentsTable,
            ExcludeAssessmentsFilters,
        },

        computed: {
            ...mapGetters([
                'exclusionAssessments',
                'allAssessmentTags',
                'allCompanyTags',
                'jobs',
                'allCompanyUsers',
                'exclusionAssessmentListFilters',
                'availableCompanies',
                'availableCustomers',
            ]),
        },

        mounted () {
            if (!this.allAssessmentTags || this.allAssessmentTags.length === 0) {
                this.$store.dispatch('getAllAssessmentTags')
            }

            if (!this.allCompanyTags || this.allCompanyTags.length === 0) {
                this.$store.dispatch('getAllCompanyTags')
            }

            if (!this.exclusionAssessments || this.exclusionAssessments.length === 0) {
                this.$store.dispatch('getFirstExclusionAssessmentsPage')
            }

            if (!this.jobs || this.jobs.length === 0) {
                this.$store.dispatch('updateJobTypes')
            }

            if (!this.allCompanyUsers || this.allCompanyUsers.length === 0) {
                this.$store.dispatch('getAllUsersInCompany')
            }

            if (!this.availableCompanies || this.availableCompanies.length === 0) {
                this.$store.dispatch('getAvailableCompanies')
            }

            if (!this.availableCustomers || this.availableCustomers.length === 0) {
                this.$store.dispatch('getAvailableCustomers')
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ExcludeAssessments {
        display: flex;
        flex-direction: row;

        &-left {
            padding-right: 2rem;
            width: 18vw;
        }

        &-right {
            width: 82vw;
        }
    }
</style>
