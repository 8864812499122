<template lang="pug">
    div(class="sz-JumpToAssessment")
        div(class="sz-JumpToAssessment-header")
            div(class="sz-JumpToAssessment-header-title") {{ $t(`management.assessmentDashboard.assessmentNavigation.header`) }}
        div(class="sz-JumpToAssessment-body")
            div(
                v-if="warning && !loading",
                class="sz-JumpToAssessment-body-warning") {{ $t(`management.assessmentDashboard.assessmentNavigation.warning`) }}
            div(v-if="loading")
                LoadingSpinner
            div(
                v-else,
                class="sz-JumpToAssessment-body-input")
                FormSectionInput(
                    class="sz-JumpToAssessment-body-input-textField",
                    :title="$t(`management.assessmentDashboard.assessmentNavigation.placeholder`)",
                    textSize="content",
                    :useInputTag="true",
                    @updated="updateAssessmentID",
                    @enter="goToAssessmentPage()")
            div(class="sz-JumpToAssessment-body-footer")
                div(
                    @click.stop="goToAssessmentPage()",
                    class="sz-JumpToAssessment-body-footer-go") {{ $t(`management.assessmentDashboard.assessmentNavigation.go`) }}
</template>

<script>
    import constants from 'helpers/constants'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import { mapGetters } from 'vuex'
    import { userCanAccessAssessment } from 'helpers/permissionsHelper'
    import FormSectionInput from 'components/Shared/FormSectionInput'

    export default {
        name: 'JumpToAssessment',

        components: {
            LoadingSpinner,
            FormSectionInput,
        },

        data () {
            return {
                assessmentID: '',
                warning: false,
                loading: false,
            }
        },

        computed: {
            ...mapGetters([
                'currentCompany',
                'currentCustomer',
                'auth0User',
            ]),
        },

        methods: {
            getRiskType (riskModule) {
                if (riskModule === constants.PAGES.ASSESSMENT.EDITOR) {
                    return constants.MODULE_RISK_TYPES[constants.RISK_MODULES.MOTION][0]
                } else {
                    return constants.MODULE_RISK_TYPES[riskModule][0]
                }
            },

            // The provided assessment ID can be prepended with their target risk module
            // However, the inclusion of this prepend makes the id unusable in API calls so we need to seperate them
            parseAssessmentID(assessmentID) {
                let prepend = assessmentID.split('-')[0]

                if (Object.values(constants.RISK_MODULES_SHORT).includes(prepend)) {
                    return [prepend, assessmentID.substring(2)]
                } else {
                    return [undefined, assessmentID]
                }
            },

            // The assessment ID can be prepended with the first letter of the target risk module
            // This allows us to navigate to a specific assessment page, otherwise we default to motion
            getRiskModule(prepend) {
                return constants.ASSESSMENT_ID_MODULE_MAP[prepend] || constants.ASSESSMENT_PAGE.MOTION
            },

            updateAssessmentID(assessmentID) {
                this.assessmentID = assessmentID
            },

            async goToAssessmentPage() {
                this.loading = true
                try {
                    let [prepend, assessmentID] = this.parseAssessmentID(this.assessmentID.trim())
                    let riskModule = this.getRiskModule(prepend)

                    let assessmentInfo = await this.$store.dispatch('getAssessmentPageMetadataFromAssessment', { assessmentID: assessmentID })
                    
                    // Re-direct to V2
                    window.location.href = `${process.env.VUE_APP_V2_BASE_URL}/assessment/${assessmentInfo.customerID}/${assessmentInfo.companyID}/${assessmentInfo.userID}/${assessmentInfo.captureID}/${assessmentInfo.metadataID}/${assessmentInfo.id}/${riskModule.toLowerCase()}`
                } catch(err) {
                    this.warning = true
                }
                this.loading = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    ::placeholder {
        color: $color-white;
        opacity: 1;
        @extend %font-content;
        font-weight: 600;
        font-size: 13px;
    }

    .sz-JumpToAssessment {
        max-width: 30rem;
        position: relative;
        width: inherit;

        &-header {
            width: 100%;
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.75rem 0;
            font-size: 14px;
            @extend %font-heading;

            &-title {
                margin-left: 1rem;
            }
        }

        &-body {
            background-color: $color-tooltip-dark;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-x: hidden;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 12px;
            &-warning {
                color: $color-risk-red;
                padding-bottom: 0.5rem;
            }

            &-input {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                align-self: center;
                background-color: $color-tooltip-dark;
                padding-right: 0.75rem;

                &-textField {
                    padding-left: 1rem;
                    padding-bottom: 1rem;
                }
            }

            &-footer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &-go {
                    @extend %button-layout;
                    background-color: $color-lifebooster-light-green;
                    padding: 0.5rem 1rem;
                    border-radius: 0;
                    width: 10%;
                    @extend %font-topbar-heading;
                }
            }
        }
    }
</style>
