<template lang="pug">
    div(class="sz-EditAssessmentTag")
        div(class="sz-EditAssessmentTag-header")
            div(class="sz-EditAssessmentTag-header-title") {{ title }}
            div(
                @click.stop="cancel()",
                class="sz-EditAssessmentTag-header-close")
                SvgIcon(
                    icon="close",
                    width="1rem",
                    height="1rem",
                    class="sz-EditAssessmentTag-header-close-icon")
        div(class="sz-EditAssessmentTag-body")
            div(class="sz-EditAssessmentTag-body-section")
                div(class="sz-EditAssessmentTag-body-section-title") {{ $t(`management.companyDashboard.tagManagement.tagName`) }}
                FormSectionInput(
                    title="",
                    textSize="content",
                    :defaultInput="defaultTag",
                    @updated="updateTag",
                    :useInputTag="changeableTag",
                    class="sz-EditAssessmentTag-body-section-input")
        div(class="sz-EditAssessmentTag-buttons")
            div(
                @click.stop="deleteAssessmentTag()",
                class="sz-EditAssessmentTag-buttons-delete") {{ $t(`management.delete`) }}
            div(
                @click.stop="save()",
                :class="'sz-EditAssessmentTag-buttons-save-' + (settingsHaveChanged ? 'valid' : 'invalid')",
                class="sz-EditAssessmentTag-buttons-save") {{ $t(`management.save`) }}
        div(
            v-if="updatingAssessmentTag",
            class="sz-EditAssessmentTag-loading")
            LoadingSpinner

</template>

<script>
    import { mapGetters } from 'vuex'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import constructModalSettings from 'mixins/modalSettings'
    import constants from 'helpers/constants'
    import formValidator from 'mixins/formValidator'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import EventBus from 'src/eventBus'
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: "EditAssessmentTag",

        components: {
            SvgIcon,
            FormSectionInput,
            LoadingSpinner,
        },

        mixins: [
            formValidator,
            constructModalSettings,
        ],

        props: {
            title: {
                type: String,
                required: true,
            },

            tag: {
                type: Object,
                required: false,
                default: () => {},
            },
        },

        data () {
            return {
                newTagName: null,
                showConfirmModal: true,
                action: '',
            }
        },

        computed: {
            ...mapGetters([
                'allAssessmentTags',
                'updatingAssessmentTag',
                'updateAssessmentTagStatus',
            ]),

            defaultTag () {
                return this.tag.name || ''
            },

            settingsHaveChanged () {
                if (!this.tag.tagID) {
                    return this.newTagName !== null
                        && this.newTagName.trim().length != 0
                }

                return this.newTagName !== null
                    && this.newTagName.toLowerCase().trim() !== this.tag.name.toLowerCase().trim()
                    && this.newTagName.trim().length != 0
            },

            submitType () {
                return this.tag.tagID
                    ? constants.COMPANY_SETTINGS.EDIT.TAGS.EDITTAG
                    : constants.COMPANY_SETTINGS.EDIT.TAGS.NEWTAG
            },

            newSettings () {
                return {
                    name: this.newTagName,
                }
            },

            isUserConfirmed () {
                return this.showConfirmModal === false
            },

            updateSucceeded () {
                return this.updateAssessmentTagStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS
            },

            changeableTag () {
                return true
            },

            isSave () {
                return this.action === constants.CONFIRM_ACTIONS.SAVE
            },

            isDelete () {
                return this.action === constants.CONFIRM_ACTIONS.DELETE
            },
        },

        watch: {
            showConfirmModal: {
                //watches if submit is confirmed by user & calls submit again
                handler: function () {
                    if (this.isUserConfirmed) {
                        if (this.isSave) {
                          this.save()
                        }
                        else if (this.isDelete) {
                          this.deleteAssessmentTag()
                        }
                    }
                },
            },
        },

        mounted () {
            EventBus.$on('USER_CONFIRMED', () => {
                this.showConfirmModal = false
            })

            EventBus.$on('USER_CANCELLED', () => {
                this.showConfirmModal = true
            })

            EventBus.$on('CLOSE_WINDOW', async() => {
                await this.$store.dispatch('getAllAssessmentTags')
            })
        },

        methods: {
            updateTag (tag) {
                this.newTagName = tag
            },

            cancel () {
                this.$emit('closeEdit')
            },

            async deleteAssessmentTag() {
                this.action = constants.CONFIRM_ACTIONS.DELETE
                if (this.showConfirmModal) {
                    return this.confirmModal(
                        this.$t(`management.assessmentDashboard.manageAssessmentTags.deleteTagTitle`),
                        this.$t(`management.assessmentDashboard.manageAssessmentTags.deleteTagMessage`, { name: this.tag.name }))
                }

                await this.$store.dispatch('deleteAssessmentTag', {
                    assessmentTagID: this.tag.id,
                })

                if (this.updateSucceeded === true) {
                    await this.successModal(
                        this.$t(`management.assessmentDashboard.manageAssessmentTags.deleteTagSuccess`, { name: this.tag.name }),
                        true)
                } else {
                    await this.failModal(
                          this.$t("pleaseTryAgain"),
                          this.$t("somethingWentWrong"),
                          () => this.showConfirmModal = true)
                }
            },

            async save() {
                if (!this.settingsHaveChanged) return

                this.action = constants.CONFIRM_ACTIONS.SAVE

                const validator = this.validateAssessmentTagSettings()
                if (!validator.valid)
                    return this.invalidModal(
                        validator,
                        this.$t(`management.companyDashboard.editUserOption.tags`),
                        false)

                if (this.showConfirmModal)
                    return this.confirmModal(
                        this.title,
                        this.$t(`management.companyDashboard.updateMessage.${this.submitType}TagConfirm`, { name: this.newSettings.name }))

                // if the current tag has an ID, then it is not a new tag.
                this.tag.id
                    ? await this.$store.dispatch('updateAssessmentTag', {
                        assessmentTagID: this.tag.id,
                        tagName: this.newSettings.name})
                    : await this.$store.dispatch('createAssessmentTag', this.newSettings.name)

                if (this.updateSucceeded === true) {
                    await this.successModal(
                        this.$t(`management.companyDashboard.updateMessage.${this.submitType}TagSuccess`, { name: this.newSettings.name }),
                        true)
                } else {
                    await this.failModal(
                          this.$t("pleaseTryAgain"),
                          this.$t("somethingWentWrong"),
                          () => this.showConfirmModal = true)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-EditAssessmentTag {
        width: 30vw;
        min-width: 10rem;
        position: relative;

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: lighten($color-body-bg, 10%);
            opacity: 0.5;
            filter: blur(1px);
            z-index: $layer-modal-loader-z-index;
        }

        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem 0;
            font-size: 13px;
            @extend %font-heading;

            &-title {
                margin-left: 1rem;
            }

            &-close {
                padding-top: 0.2rem;
                margin-right: 0.75rem;
                cursor: pointer;
            }
        }

        &-body {
            background-color: $color-box-background;
            width: 100%;
            height: 25vh;

            &-section {
                display: flex;
                padding-top: 3vw;

                &-title {
                    @extend %font-heading;
                    font-size: 0.75rem;
                    margin: 0.3rem 1.5rem;
                }

                &-input {
                    width: 64%;
                }
            }
        }

        &-buttons {
            background-color: $color-box-background;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-bottom: 1rem;
            @extend %font-topbar-heading;

            &-delete {
                @extend %button-layout;
                padding: 0.5rem 1rem;
                border-radius: 0;
                background-color: $color-box-outline-dark;
                color: $color-white;
            }

            &-save {
                @extend %button-layout;
                padding: 0.5rem 1rem;
                border-radius: 0;

                &-invalid {
                    background-color: $color-button-disabled-bg;
                    color: $color-unselected-text;
                    cursor: not-allowed;
                }

                &-valid {
                    background-color: $color-lifebooster-light-green;
                }
            }
        }
    }
</style>
