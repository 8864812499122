<template lang="pug">
    form(class="sz-DefaultAssessmentTagsFilters")
        div(class="sz-DefaultAssessmentTagsFilters-body")
            div(class="sz-DefaultAssessmentTagsFilters-body-title") {{ "Filter By" }}
            div(class="sz-DefaultAssessmentTagsFilters-body-section")
                FormSectionTagSelection(
                    class="sz-DefaultAssessmentTagsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.orgChartTags`)",
                    :options="orgChartTagOptions",
                    :defaultSelectedTags="defaultOrgChartTags",
                    @updateSelectedTags="updateSelectedOrgChartTags")
            div(class="sz-DefaultAssessmentTagsFilters-body-section")
                FormSectionTagSelection(
                    class="sz-DefaultAssessmentTagsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.jobs`)",
                    :options="jobOptions",
                    :defaultSelectedTags="defaultJobs",
                    @updateSelectedTags="updateSelectedJobs")
            div(class="sz-DefaultAssessmentTagsFilters-body-section")
                FormSectionTagSelection(
                    class="sz-DefaultAssessmentTagsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.genders`)",
                    :options="genderOptions",
                    :defaultSelectedTags="defaultGenders",
                    @updateSelectedTags="updateSelectedGenders")
            div(class="sz-DefaultAssessmentTagsFilters-body-section")
                FormSectionTagSelection(
                    class="sz-DefaultAssessmentTagsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.names`)",
                    :options="nameOptions",
                    :defaultSelectedTags="defaultNames",
                    @updateSelectedTags="updateSelectedNames")
            div(class="sz-DefaultAssessmentTagsFilters-body-footer")
                div(
                    @click="resetFilters",
                    class="sz-DefaultAssessmentTagsFilters-body-footer-buttons \
                        sz-DefaultAssessmentTagsFilters-body-footer-buttons-reset") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.reset`)}}
                div(
                    type="submit",
                    @click="applyFilters",
                    class="sz-DefaultAssessmentTagsFilters-body-footer-buttons \
                        sz-DefaultAssessmentTagsFilters-body-footer-buttons-apply") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentFilters.apply`)}}

</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
    import FormSectionDropdownSingleSelect from "components/Shared/FormSectionDropdownSingleSelect"
    import FormSectionDateRangePicker from 'components/Shared/FormSectionDateRangePicker'
    import EventBus from 'src/eventBus'

    export default {
        name: "DefaultAssessmentTagsFilters",

        components: {
            FormSectionTagSelection,
            FormSectionDropdownSingleSelect,
            FormSectionDateRangePicker,
        },

        data () {
            return {
                selectedOrgChartTags: [],
                selectedJobs: [],
                selectedGenders: [],
                selectedUserIDs: [],
                interval: '',
            }
        },

        computed: {
            ...mapGetters([
                'allCompanyTags',
                'jobs',
                'applyDefaultAssessmentTagsUsers',
                'applyDefaultAssessmentTagsFilters',
                'reqUserProfile',
            ]),

            reqUserViewerTags () {
                return this.reqUserProfile.viewertags
            },

            defaultOrgChartTags () {
                return (this.applyDefaultAssessmentTagsFilters.orgChartTags || [])
                    .map(tagID => this.allCompanyTags
                        .find(tag => tag.tagID === tagID).name)
            },

            defaultJobs () {
                return this.applyDefaultAssessmentTagsFilters.jobs || []
            },

            defaultGenders () {
                return this.applyDefaultAssessmentTagsFilters.genders || []
            },

            defaultNames () {
                return (this.applyDefaultAssessmentTagsFilters.userIDs || [])
                    .map(userID => {
                        let user = this.applyDefaultAssessmentTagsUsers.find(user => user.userID === userID)
                        return `${user.firstName} ${user.lastName}`
                    })
            },

            orgChartTagOptions () {
                let viewableTagNames = this.reqUserViewerTags.map((tag) => { return tag.tagID })
                let viewableTags = this.allCompanyTags.filter((tag) => viewableTagNames.includes(tag.tagID))

                return viewableTags.map(tag => tag.name)
            },

            jobOptions () {
                return this.jobs.map(job => job.name)
            },

            genderOptions () {
                return Object.values(constants.GENDER_NAME_API_MAP)
            },

            nameOptions () {
                return this.applyDefaultAssessmentTagsUsers
                    .filter(user => (user.firstName !== undefined && user.lastName !== undefined))
                        .map(user => {
                              return `${user.firstName} ${user.lastName}`
                        })
            },

            currentFilters () {
                return {
                  orgChartTags: this.selectedOrgChartTags,
                  jobs: this.selectedJobs,
                  genders: this.selectedGenders,
                  userIDs: this.selectedUserIDs,
                }
            },
        },

        methods: {
            updateSelectedOrgChartTags(newTags) {
                if (newTags && newTags.length) {
                    newTags = newTags
                        .map(tagName => this.allCompanyTags
                            .find(tag => tag.name === tagName).tagID)
                }

                this.selectedOrgChartTags = newTags
            },

            updateSelectedJobs(newJobs) {
                this.selectedJobs = newJobs
            },

            updateSelectedGenders(newGenders) {
                this.selectedGenders = newGenders.map((gender) => {
                    return constants.GENDER_API_NAME_MAP[gender]
                })
            },

            updateSelectedNames(newNames) {
                if (newNames && newNames.length) {
                    newNames = newNames
                        .map(name => {
                            let splitName = name.split(" ")
                            return this.applyDefaultAssessmentTagsUsers.find(user => (user.firstName === splitName[0] && user.lastName === splitName[1])).userID
                        })
                }
                this.selectedUserIDs = newNames
            },

            async resetFilters () {
                this.selectedOrgChartTags = []
                this.selectedJobs = []
                this.selectedGenders = []
                this.selectedUserIDs = []

                await Promise.all([
                    this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', this.currentFilters),
                    this.$store.dispatch('updateApplyDefaultAssessmentTagsFilters', this.currentFilters),
                    EventBus.$emit('CLEAR_SELECTED_TAGS'),
                ])

                this.publishUpdateEvent()
            },

            async applyFilters () {
                await Promise.all([
                    this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', this.currentFilters),
                    this.$store.dispatch('updateApplyDefaultAssessmentTagsFilters', this.currentFilters),
                ])

                this.publishUpdateEvent()
            },

            publishUpdateEvent () {
                EventBus.$emit('DEFAULT_TAGS_FILTERS_UPDATED')
            },

            async getReqUserViewableTags() {
                this.reqUserViewableTags = await this.$store.dispatch('getUserCompleteProfileByID', this.userID)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-DefaultAssessmentTagsFilters {
        position: relative;
        width: inherit;
        height: 100%;

        &-body {
            background-color: $color-tooltip-dark;
            max-height: 61.5vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem 0 1rem 0;

            &-title {
                align-self: flex-start;
                padding: 0rem 1rem 0.5rem 1rem;
                @extend %font-heading;
                font-size: 14px;
            }

            &-section {
                padding: 0.25rem 1rem 0.25rem 1rem;

                &-select {
                    font-size: 12px;
                }
            }

            &-footer {
                padding-top: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &-buttons {
                    cursor: pointer;
                    @extend %font-topbar-heading;

                    &-reset {
                        color: $color-white;
                        margin: 0 1rem;
                        font-size: 12px;
                    }

                    &-apply {
                        @extend %button-layout;
                        background-color: $color-lifebooster-light-green;
                        padding: 0.5rem 1rem;
                        border-radius: 0;
                    }
                }
            }
        }
    }
</style>
