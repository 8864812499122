<template lang="pug">
    div(class="sz-AssessmentTable")
        div(class="sz-AssessmentTable-optionsBar")
            div(
                @click.stop="openEditAssessment",
                class="sz-AssessmentTable-optionsBar-edit") {{ selectedAssessmentsCount }}
            div(class="sz-AssessmentTable-optionsBar-search")
                input(
                    type="text",
                    v-model="search",
                    placeholder="Type here to search",
                    class="sz-AssessmentTable-optionsBar-search-input")
                SvgIcon(
                    icon="search",
                    width="0.5rem",
                    height="0.5rem",
                    class="sz-AssessmentTable-optionsBar-search-icon")
        div(class="sz-AssessmentTable-wrapper")
            table(class="sz-AssessmentTable-table sticky-header")
                thead
                    tr(class="sz-AssessmentTable-headers")
                        th()
                            div(
                                @click.stop="toggleSelectAll",
                                :class="`sz-AssessmentTable-row-checkbox-` + (isSelectAll ? 'checked ': 'unchecked')",
                                class="sz-AssessmentTable-row-checkbox")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-AssessmentTable-headers-header-${header}`")
                            div(class="sz-AssessmentTable-headers-header-content")
                                span {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.headers.${header}`) }}
                                SvgIcon(
                                    icon="arrow",
                                    width="10",
                                    height="10",
                                    class="sz-AssessmentTable-headers-header-arrow",
                                    :class="[currentSortDirection, header === currentSort ? 'show' : '']")
                tbody
                    tr(
                        v-if="companyAssessmentListLoading || !assessments.length",
                        class="sz-AssessmentTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-AssessmentTable-empty-cell")
                            span(
                                v-if="companyAssessmentListLoading"
                                class="sz-AssessmentTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.loading`) }}
                            span(
                                v-else-if="!assessments.length"
                                class="sz-AssessmentTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.empty`) }}
                    tr(
                        v-if="!companyAssessmentListLoading && assessments.length",
                        v-for="assessment of assessments",
                        :key="assessment.id",
                        class="sz-AssessmentTable-row")
                        td()
                            div(
                                @click.stop="assessmentSelected(assessment)"
                                :class="`sz-AssessmentTable-row-checkbox-` + (isAssessmentSelected(assessment) ? 'checked ': 'unchecked')",
                                class="sz-AssessmentTable-row-checkbox"
                                )
                        td(
                            @click="assessmentSelected(assessment)",
                            v-for="header of headers",
                            :key="header",
                            v-truncated-tooltip="",
                            :id="`sz-AssessmentTable-row-item-${header}-${assessment.metadataID}`"
                            :class="`sz-AssessmentTable-row-item ${header}`") {{ assessment[header] ?  joinAssessmentItems(assessment[header]) : null }}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { cloneDeep, get } from 'lodash'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import modalSettings from 'mixins/modalSettings'
    import formValidator from 'mixins/formValidator'
    import SvgIcon from 'components/Shared/SvgIcon'
    import EventBus from 'src/eventBus'
    import moment from 'moment-timezone'
    import { splitFirstNameLastName } from '@/helpers/utilities'
    import { getOCTIntersection } from '@/helpers/orgChartTagHelpers'

    export default {
        name: 'AssessmentTable',

        components: {
            SvgIcon,
        },

        directives: {
            TruncatedTooltip,
        },

        mixins: [
            modalSettings,
            formValidator,
        ],

        props: {
            viewerOCTs: {
                type: Array,
                required: true,
                default: () => [],
            },
        },

        data () {
            return {
                currentSort: constants.ASSESSMENT_TABLE_HEADERS.START,
                currentSortDirection: 'down',
                search: '',
                selectedAssessments: [],
                selectAll: false,
                showEditOptions: false,
            }
        },

        computed: {
            ...mapGetters([
                'companyAssessmentListLoading',
                'allCompanyAssessments',
                'assessmentTagMapper',
                'companyTagMapper',
            ]),

            headers () {
                return constants.ASSESSMENT_TABLE_HEADERS
            },

            assessments () {
                // Need to clone so the start/end time does not get overwritten
                let allAssessments = cloneDeep(this.allCompanyAssessments) || []

                return allAssessments
                    .map((assessment) => {
                        assessment['orgChartTags'] = this.companyTagMapper[getOCTIntersection(assessment.orgChartTags, this.viewerOCTs)]
                        assessment['assessmentTags'] = assessment.assessmentTags.map(tag => { return this.assessmentTagMapper[tag] })
                        assessment['start'] = moment.tz((assessment.start * constants.UNIX_MILLISECONDS), assessment.timezone).format('LL LTS')
                        assessment['end'] = moment.tz((assessment.end * constants.UNIX_MILLISECONDS), assessment.timezone).format('LL LTS')
                        return assessment
                    })
                    .filter((assessment) => {
                        let fullName = splitFirstNameLastName(this.search.toLowerCase())
                        return (get(assessment, 'firstName', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1) ||
                        (get(assessment, 'lastName', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1) ||
                        (get(assessment, 'job', '').trim().toLowerCase().indexOf(this.search.toLowerCase().trim()) > -1) || 
                        ((get(assessment, 'firstName', '').trim().toLowerCase().indexOf(fullName.firstName) > -1)
                        && (get(assessment, 'lastName', '').trim().toLowerCase().indexOf(fullName.lastName) > -1))
                    })
                    .sort((a,b) => {
                        let direction = 1
                        let valA = this.sortCaseInsensitive ? get(a, this.currentSort, '').toLowerCase() : get(a, this.currentSort, '')
                        let valB = this.sortCaseInsensitive ? get(b, this.currentSort, '').toLowerCase() : get(b, this.currentSort, '')
                        if (this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.START || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.END) {
                          valA = moment.utc(valA)
                          valB = moment.utc(valB)
                        }
                        if (this.currentSortDirection === 'down') direction = -1
                        if (valA === null || valA === '' || valA.length === 0) return direction
                        if (valB === null || valB === '' || valB.length === 0) return -1 * direction
                        if (valA < valB) return -1 * direction
                        if (valA > valB) return direction
                        return 0
                    })
            },

            selectedAssessmentsCount() {
              return `${this.$t(`management.assessmentDashboard.assignAssessmentTags.edit`)} (${this.selectedAssessments.length})`
            },

            isSelectAll () {
                return this.selectAll && this.selectedAssessments.length === this.assessments.length
            },

            sortCaseInsensitive() {
                return this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.F_NAME
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.L_NAME
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.JOB
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.GENDER
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.OCTS
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.ASSESSMENT_TAGS
            },
        },

        mounted () {
            EventBus.$on('UPDATE_SELECTED_ASSESSMENTS', assessmentMetadataID => {
                this.selectedAssessments = this.selectedAssessments.filter(assessment => assessment.assessmentMetadataID !== assessmentMetadataID)
            })

            EventBus.$on('ASSESSMENT_FILTERS_UPDATED', () => {
                this.selectedAssessments = []
            })
        },

        methods: {
            setCurrentSort (header) {
                if (header === this.currentSort) {
                    this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
                }
                this.currentSort = header
            },

            openEditAssessment() {
                if (this.selectedAssessments.length > 0) {
                    this.$store.dispatch('showModal', this.constructModalSettings(
                        constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_BULK_EDIT_ASSESSMENTS,
                        false,
                        { assessments: this.selectedAssessments },
                        true,
                        true)
                    )
                } else {
                    this.failModal(
                       this.$t("management.assessmentDashboard.assignAssessmentTags.assessmentTable.noneSelectedMesssage"),
                       this.$t("management.assessmentDashboard.assignAssessmentTags.assessmentTable.noneSelectedWarning")
                    )
                }
            },

            isAssessmentSelected (assessment) {
                return this.selectedAssessments.some(selectAssessment => selectAssessment.assessmentMetadataID === assessment.metadataID)
            },

            assessmentSelected (assessment) {
                if (this.isAssessmentSelected(assessment)){
                    this.unSelectAssessment(assessment)
                    return false
                } else {
                    this.selectedAssessments.push(this.extractEditInfo(assessment))
                    return true
                }
            },

            unSelectAssessment (selectedAssessment) {
                let currentlySelected = this.selectedAssessments.filter(currentSelect => currentSelect.assessmentMetadataID !== selectedAssessment.metadataID)
                this.selectedAssessments = currentlySelected
            },

            toggleSelectAll () {
                this.selectAll ?
                    this.selectedAssessments = [] :
                    this.selectedAssessments = this.assessments.map(assessment => {
                        return this.extractEditInfo(assessment)
                    })
                this.selectAll = !this.selectAll
            },

            toggleEditAssessment () {
                if (this.selectedAssessments.length === 1){
                    this.openEditUserModal(this.selectedAssessments[0])
                } else {
                    this.showEditOptions = !this.showEditOptions
                }
            },

            closeEditDropdown () {
                this.showEditOptions = false
            },

            joinAssessmentItems (array) {
                if (Array.isArray(array) && array.length > 1) {
                    return array.join(', ')
                } else {
                    return array.toString()
                }
            },

            extractEditInfo (assessment) {
                return {
                  captureID: assessment.captureID,
                  assessmentMetadataID: assessment.metadataID,
                  firstName: assessment.firstName,
                  lastName: assessment.lastName,
                  start: assessment.start,
                  end: assessment.end,
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: #E5E5E5;
    }

    td, th {
        padding: 0.5rem;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;

    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top:0;
            background-color: $color-box-background ;
        }
    }

    .sz-AssessmentTable {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem;
            font-size: 13px;

            @extend %font-heading;
            @include search-bar;

            &-edit {
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    color: $color-table-row-silver;
                }
            }

            &-search {
                margin-left: auto;
                width: 30%;
                min-width: 9rem;
            }
        }

        &-wrapper {
            width: inherit;
            max-height: 60vh;
            overflow: scroll;
            @include scrollbar-widget;
            background-color: $color-box-background;
        }

        &-headers {
            &-header {
                &-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                }

                &-arrow {
                    visibility: hidden;
                    padding: 1px 3px;
                }

                &-firstName {
                    width: 12.5%;
                    overflow: hidden;
                }

                &-lastName {
                    width: 12.5%;
                    overflow: hidden;
                }

                &-start {
                    width: 12.5%;
                }

                &-end {
                    width: 12.5%;
                }

                &-job {
                    width: 12.5%;
                    overflow: hidden;
                }

                &-gender {
                    width: 12.5%;
                }

                &-orgChartTags {
                    width: 12.5%;
                }

                &-assessmentTags {
                    width: 12.5%;
                }
            }
        }

        &-row {
            cursor: pointer;

            &:hover {
                background: $color-table-row-hover;
                color: #E5E5E5
            }

            &-item {
                text-align: left;

                &.firstName, &.lastName {
                    max-width: 3rem;
                }

                &.start, &.end {
                    max-width: 5rem;
                }

                &.job {
                    width: 3rem;
                }

                &.gender {
                    max-width: 8rem;
                }

                &.orgChartTags, &.assessmentTags {
                    max-width: 8rem;
                }
            }

            &-checkbox {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                &-checked {
                    background-color: $color-box-silver;
                }
            }
        }
    }

    .show {
        visibility: visible;
    }

    .up {
        transform: rotate(180deg);
    }
    .slide-enter-active {
        transition: all .3s ease;
    }
    .slide-leave-active {
        transition: all .2s ease;
    }
    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
    }
    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }
</style>
