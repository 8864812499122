<template lang="pug">
  div(class="sz-CompanyAnonymousMode")
      div(class="sz-CompanyAnonymousMode-title") {{ $t(`management.companyDashboard.anonymousModeSettings.title`) }}
      div(
          v-if="updatingCompanyInfo",
          class="sz-CompanyAnonymousMode-sectionSettings sz-CompanyAnonymousMode-sectionSettings-loading")
          LoadingSpinner
      div(
          :class="`sz-CompanyAnonymousMode-sectionSettings${updatingCompanyInfo ? `-saving` : ``}`",
          class="sz-CompanyAnonymousMode-sectionSettings")
          div(v-if="isCustomerAnon"
              class="sz-CompanyAnonymousMode-sectionSettings-info") {{ $t(`management.companyDashboard.customerAnonMessage`)}}
          div(class="sz-CompanyAnonymousMode-sectionSettings-firstToggle")
              FormSectionToggle(
                  :title="$t(`management.companyDashboard.anonymousModeSettings.companyAnon`)",
                  :defaultChecked="defaultSettings.companyAnon",
                  :options="anonymityOptions",
                  :tooltip="$t('management.companyDashboard.anonymousModeSettings.companyAnonText')",
                  @changed="updateCompanyAnon")
          div(class="sz-CompanyAnonymousMode-sectionSettings-toggle")
              FormSectionToggle(
                  :title="$t(`management.companyDashboard.anonymousModeSettings.companyVisible`)",
                  :defaultChecked="defaultSettings.companyVisible",
                  :options="visibilityOptions",
                  :tooltip="$t(`management.companyDashboard.anonymousModeSettings.companyVisibleText`, {customer: customerName})",
                  @changed="updateCompanyVisible")
          div(class="sz-CompanyAnonymousMode-sectionSettings-input")
              FormSectionInput(
                  :title="$t(`management.companyDashboard.anonymousModeSettings.mapv`)",
                  :defaultInput="defaultSettings.MAPV",
                  @updated="updateMAPV")
          div(class="sz-CompanyAnonymousMode-buttons")
              div(class="sz-CompanyAnonymousMode-buttons-submit")
                  div(
                      @click="submit",
                      :class="{'sz-CompanyAnonymousMode-buttons-submit-button-inactive': !settingsHaveChanged || updatingCompanyInfo}"
                      class="sz-CompanyAnonymousMode-buttons-submit-button") {{ $t(`management.save`) }}
</template>

<script>
import FormSectionInput from 'components/Shared/FormSectionInput'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import formValidator from 'mixins/formValidator'

import { mapGetters } from 'vuex'
import constants from 'helpers/constants'
import constructModalSettings from 'mixins/modalSettings'
import LoadingSpinner from '../../Shared/LoadingSpinner'

export default {
  name: 'CompanyAnonymousMode',

  components: {
    FormSectionInput,
    FormSectionToggle,
    LoadingSpinner,
  },

  mixins: [formValidator, constructModalSettings],

  data() {
    return {
      newCompanyAnon: null,
      newCompanyVisible: null,
      newMAPV: null,
      status: constants.CRUD_OPERATION_STATUSES.SUCCESS,
    }
  },

  computed: {
    ...mapGetters([
      'currentCustomer',
      'companyInfo',
      'updatingCompanyInfo',
      'updateCompanyInfoStatus',
    ]),

    newSettings() {
      return {
        companyAnon:
          typeof this.newCompanyAnon === 'boolean'
            ? this.newCompanyAnon
            : this.companyInfo.anonymousMode.companyAnon,
        companyVisible:
          typeof this.newCompanyVisible === 'boolean'
            ? this.newCompanyVisible
            : this.companyInfo.anonymousMode.companyVisible,
        MAPV:
          typeof this.newMAPV === 'string'
            ? parseInt(this.newMAPV)
            : this.companyInfo.anonymousMode.companyMAPV,
      }
    },

    defaultSettings() {
      return {
        companyAnon:
          typeof this.companyInfo.anonymousMode.companyAnon === 'boolean'
            ? this.companyInfo.anonymousMode.companyAnon
            : null,
        companyVisible:
          typeof this.companyInfo.anonymousMode.companyVisible === 'boolean'
            ? this.companyInfo.anonymousMode.companyVisible
            : null,
        MAPV: this.companyInfo.anonymousMode.companyMAPV.toString() || '',
      }
    },

    customerName() {
      return this.companyInfo.customer
    },

    isCustomerAnon() {
      return this.companyInfo.anonymousMode.customerAnon === true
    },

    anonymityOptions() {
      return [this.$t(`management.visible`), this.$t(`management.anonymous`)]
    },

    visibilityOptions() {
      return [this.$t(`management.anonymous`), this.$t(`management.visible`)]
    },

    settingsHaveChanged() {
      return typeof this.newMAPV === 'string'
        ? this.newMAPV !== this.companyInfo.anonymousMode.companyMAPV.toString()
        : false
    },

    statusIsError() {
      return this.status === constants.CRUD_OPERATION_STATUSES.FAIL
    },

    updateFailed() {
      return this.updateCompanyInfoStatus === constants.CRUD_OPERATION_STATUSES.FAIL
    },

    updateSucceeded() {
      return this.updateCompanyInfoStatus === constants.CRUD_OPERATION_STATUSES.SUCCESS
    },

    successMessage() {
      return this.$t(`management.companyDashboard.updateMessage.updateMAPVSuccess`, {
        MAPV: this.newMAPV,
      })
    },
  },

  watch: {
    settingsHaveChanged() {
      if (this.settingsHaveChanged) {
        this.setUpdateStatus(constants.CRUD_OPERATION_STATUSES.SUCCESS)
      }
    },
  },

  mounted() {
    if (!this.companyInfo) {
      this.$store.dispatch('getCompanyInfo')
    }
  },

  methods: {
    async updateCompanyAnon(newCompanyAnon) {
      try {
        await this.$store.dispatch('updateCompanyAnonymity', { companyAnon: newCompanyAnon })
        this.newCompanyAnon = newCompanyAnon
        if (this.updateSucceeded) {
          this.refreshCompanyInfo()
        }
      } catch (e) {
        this.setUpdateStatus(constants.CRUD_OPERATION_STATUES.FAIL)
        this.failModal(this.$t('pleaseTryAgain'), this.$t('somethingWentWrong'), () => {})
      }
    },

    async updateCompanyVisible(newCompanyVisible) {
      try {
        await this.$store.dispatch('updateCompanyVisibility', { companyVisible: newCompanyVisible })
        this.newCompanyVisible = newCompanyVisible
        if (this.updateSucceeded) {
          this.refreshCompanyInfo()
        }
      } catch (e) {
        this.setUpdateStatus(constants.CRUD_OPERATION_STATUES.FAIL)
        this.failModal(this.$t('pleaseTryAgain'), this.$t('somethingWentWrong'), () => {})
      }
    },

    updateMAPV(newMAPV) {
      this.newMAPV = newMAPV
    },

    async submit() {
      if (this.settingsHaveChanged) {
        const validator = this.validateCompanyAnonymousModeSettings()
        if (validator.valid) {
          await this.$store.dispatch('updateMAPV', this.newSettings)
          if (this.updateSucceeded) {
            this.successModal(this.successMessage)
            this.refreshCompanyInfo()
          } else {
            this.setUpdateStatus(constants.CRUD_OPERATION_STATUSES.FAIL)
            this.failModal(this.$t('pleaseTryAgain'), this.$t('somethingWentWrong'), () => {})
          }
        } else {
          this.setUpdateStatus(constants.CRUD_OPERATION_STATUSES.FAIL)
          this.invalidModal(validator, 'page', false)
        }
      }
    },

    refreshCompanyInfo() {
      this.$store.dispatch('getCompanyInfo')
    },

    setUpdateStatus(newStatus) {
      this.status = newStatus
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

.sz-CompanyAnonymousMode {
  background-color: $color-box-background;
  display: flex;
  flex-direction: column;
  width: 35vw;

  &-title {
    @extend %font-heading;
    display: flex;
    background-color: $color-black;
    font-size: 13px;
    text-align: left;
    padding: 0.5rem 0;
    padding-top: 0.8rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
  }

  &-status {
    @extend %font-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.5rem;
  }

  &-sectionSettings {
    @extend %font-topbar-heading;
    font-size: 12px;
    color: $color-white;
    padding-left: 2rem;
    padding-right: 2rem;

    &-firstToggle {
      padding-top: 1rem;
      padding-bottom: 1.7rem;
    }

    &-toggle {
      padding-bottom: 1.7rem;
    }

    &-input {
      width: 100%;
      padding-bottom: 1.7rem;
    }

    &-saving {
      filter: blur(1px);
      pointer-events: none;
    }

    &-loading {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-table-row-silver;
      opacity: 0.5;
      filter: blur(1px);
      z-index: $layer-modal-loader-z-index;
    }

    &-info {
      @extend %font-content;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 1rem;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    @extend %font-topbar-heading;
    color: $color-white;
    margin: 0 1rem;
    cursor: pointer;
    padding-bottom: 2rem;

    &-submit {
      display: flex;
      justify-content: center;

      &-button {
        background-color: $color-lifebooster-light-green;
        @extend %button-layout;
        padding: 0.5rem 1rem;
        border-radius: 0;

        &-inactive {
          background-color: $color-button-disabled-bg;
          color: $color-unselected-text;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
