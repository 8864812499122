<template lang="html">
  <div class="sz-AssessmentHistoryTable">
    <div class="sz-AssessmentHistoryTable-optionsBar">
      <div class="sz-AssessmentHistoryTable-optionsBar-title">
        {{ $t(`management.assessmentDashboard.assessmentHistoryTable.title`) }}
      </div>
      <PageNavigationButton
        class="sz-AssessmentHistoryTable-optionsBar-pageNavigationButtons"
        next-page-endpoint="getNextCaptureProgress"
        prev-page-endpoint="getPrevCaptureProgress"
        :disable-next="disableNextPage"
        :disable-prev="disablePrevPage"
        :current-page-number="currentPageNumber"
      />
    </div>
    <div v-if="isLoading">
      <LoadingSpinner color="light" />
    </div>
    <div class="sz-AssessmentHistoryTable-wrapper">
      <table class="sz-AssessmentHistoryTable-table sticky-header">
        <thead>
          <tr class="sz-AssessmentHistoryTable-headers">
            <th
              v-for="header of headers"
              :key="header"
              :class="`sz-AssessmentHistoryTable-headers-header-${header}`"
            >
              <div class="sz-AssessmentHistoryTable-headers-header-content">
                <span>{{
                  $t(
                    `management.assessmentDashboard.assessmentHistoryTable.headers.${header}`
                  )
                }}</span>
              </div>
            </th>
            <th
              v-for="taskHeader of taskBreakdownHeaders"
              :key="taskHeader"
              :class="`sz-AssessmentHistoryTable-headers-header-${taskHeader}`"
            >
              <div class="sz-AssessmentHistoryTable-headers-header-content">
                <span>{{
                  $t(
                    `management.assessmentDashboard.assessmentHistoryTable.headers.${taskHeader}`
                  )
                }}</span>
              </div>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="captureProgressListLoading || !captures.length"
            class="sz-AssessmentHistoryTable-empty"
          >
            <td
              class="sz-AssessmentHistoryTable-empty-cell"
              :colspan="headers.length"
            >
              <span
                v-if="captureProgressListLoading"
                class="sz-AssessmentHistoryTable-empty-cell-loading"
              >{{
                $t(
                  `management.assessmentDashboard.assessmentHistoryTable.loading`
                )
              }}</span><span
                v-else-if="!captures.length"
                class="sz-AssessmentHistoryTable-empty-cell-loading"
              >{{
                $t(
                  `management.assessmentDashboard.assessmentHistoryTable.empty`
                )
              }}</span>
            </td>
          </tr>
          <tr
            v-for="capture of captures"
            v-if="!captureProgressListLoading && captures.length"
            :key="capture.assessmentID"
            class="sz-AssessmentHistoryTable-row"
          >
            <td
              v-for="header of headers"
              :id="`sz-AssessmentHistoryTable-row-item-${header}-${capture.id}`"
              :key="header"
              v-truncated-tooltip=""
              :class="`sz-AssessmentHistoryTable-row-item ${header}`"
            >
              <div
                :class="
                  `${
                    isStateHeader(header) && userHasLBAssessorRole
                      ? 'buttons'
                      : ''
                  } ${isStateHeader(header) && 'centerAligned'}`
                "
                @click="openCaptureDetails(header, capture)"
              >
                {{ capture[header] ? capture[header] : null }}
              </div>
            </td>
            <td
              v-if="!hasTaskBreakdownOriginalFilename(capture)"
              class="sz-AssessmentHistoryTable-row-item"
              @click="openUploadTaskBreakdown(capture)"
            >
              <div
                v-tooltip="taskBreakdownUploadTooltip(capture)"
                class="buttons"
                :class="`buttons${isComplete(capture) ? '' : '-disabled'}`"
              >
                {{ $t("taskAssessment.uploadTaskBreakdown.uploadFile") }}
              </div>
            </td>
            <td
              v-else
              class="sz-AssessmentHistoryTable-row-item manageFile"
              @click="openManageTaskBreakdown(capture)"
            >
              <div class="buttons">
                {{ $t("taskAssessment.manageTaskBreakdown.manageFile") }}
              </div>
            </td>
            <td
              class="sz-AssessmentHistoryTable-row-item"
              @click="exportTaskAssessment(capture)"
            >
              <div
                v-if="userHasAssessorRole"
                class="buttons"
                :class="
                  `buttons${
                    isComplete(capture) && hasTaskBreakdownOriginalFilename(capture)
                      ? ''
                      : '-disabled'
                  }`
                "
              >
                {{ $t("taskAssessment.uploadTaskBreakdown.exportFile") }}
              </div>
            </td>
            <td
              v-if="isComplete(capture) && userHasAssessorRole"
              :id="capture.id"
              :class="`sz-AssessmentHistoryTable-row-item jump`"
              @click.stop="toggleDropdown(capture.id)"
            >
              {{ "Jump To" }}
              <SvgIcon
                class="sz-AssessmentHistoryTable-dropdownArrow"
                icon="arrow"
                width="15"
                height="15"
                :class="
                  `sz-AssessmentHistoryTable-dropdownArrow${
                    isDropdownOpen(capture.id) ? `-open` : `-closed`
                  }`
                "
              />
            </td>
            <transition name="slide">
              <DropdownAssessmentNavigator
                v-if="isDropdownOpen(capture.id)"
                :capture-i-d="capture.id"
                @closeDropdown="toggleDropdown()"
              />
            </transition>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import { cloneDeep, get } from 'lodash'
import SvgIcon from 'components/Shared/SvgIcon'
import moment from 'moment-timezone'
import { TruncatedTooltip } from 'directives/truncatedTooltip'
import DropdownAssessmentNavigator from './DropdownAssessmentNavigator'
import PageNavigationButton from "components/Navigation/PageNavigationButton"
import modalSettings from 'mixins/modalSettings'
import { tooltip } from 'directives/tooltip'
import formValidator from 'mixins/formValidator'
import constructModalSettings from 'mixins/modalSettings'
import { stringify } from 'csv-stringify/lib/sync'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import EventBus from 'src/eventBus'

export default {
  name: 'AssessmentHistoryTable',

  components: {
    SvgIcon,
    DropdownAssessmentNavigator,
    PageNavigationButton,
    LoadingSpinner,
  },

  directives: {
    TruncatedTooltip,
    tooltip,
  },

  mixins: [
    modalSettings,
    formValidator,
    constructModalSettings,
  ],

  data() {
    return {
      currentSort: constants.ASSESSMENT_PROGRESS_TABLE_HEADERS.START,
      currentSortDirection: 'down',
      search: '',
      selectedCaptureIDs: [],
      selectAll: false,
      showEditOptions: false,
      dropdownOpen: '',
      taskBreakdownHeaders: ['taskBreakdown'], // Reintroduce taskAssessment after SZ-648 is complete ['taskBreakdown', 'taskAssessment'],
      requestedDownload: false,
      isLoading: null,
      activeCapture: null,
    }
  },

  computed: {
    ...mapGetters([
      'captureProgressListLoading',
      'captureProgressPage',
      'hasPrevCaptureProgressPage',
      'hasNextCaptureProgressPage',
      'captureProgressCurrentPageNumber',
      'getCaptureDetails',
      'userHasLBAssessorRole',
      'userHasAssessorRole',
    ]),

    headers() {
      return constants.ASSESSMENT_PROGRESS_TABLE_HEADERS
    },

    captures() {
      // Need to clone so the start time does not get overwritten
      let captureProgress = cloneDeep(this.captureProgressPage)
      return captureProgress
        .map((capture) => {
          capture['startTime'] = capture['start']
          capture['start'] = moment.tz((capture.start * constants.UNIX_MILLISECONDS), capture.timezone).format('LL LTS')
          capture['setID'] = capture.setID.substring(0, 2) === '0x' ? capture.setID.slice(2) : capture.setID
          return capture
          // Check if any of the columns contain the characters entered into the search bar and filter out the results
        }).filter((capture) =>
          (get(capture, 'firstName', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (get(capture, 'lastName', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (get(capture, 'setID', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (get(capture, 'status', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (get(capture, 'company', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (get(capture, 'customer', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          // Sort table values based on the selected column
        ).sort((a, b) => {
          let direction = 1
          let valA = this.sortCaseInsensitive ? get(a, this.currentSort, '').toLowerCase() : get(a, this.currentSort, '')
          let valB = this.sortCaseInsensitive ? get(b, this.currentSort, '').toLowerCase() : get(b, this.currentSort, '')
          if (this.currentSort === constants.ASSESSMENT_PROGRESS_TABLE_HEADERS.START) {
            valA = moment.utc(valA)
            valB = moment.utc(valB)
          }
          if (this.currentSortDirection === 'down') direction = -1
          if (valA === null || valA === '' || valA.length === 0) return direction
          if (valB === null || valB === '' || valB.length === 0) return -1 * direction
          if (valA < valB) return -1 * direction
          if (valA > valB) return direction
          return 0
        })
    },

    disablePrevPage() {
      return this.hasPrevCaptureProgressPage
    },

    disableNextPage() {
      return this.hasNextCaptureProgressPage
    },

    currentPageNumber() {
      return this.captureProgressCurrentPageNumber
    },

    sortCaseInsensitive() {
      return this.currentSort === constants.USER_CHARACTERISTICS.EMAIL
        || this.currentSort === constants.USER_CHARACTERISTICS.F_NAME
        || this.currentSort === constants.USER_CHARACTERISTICS.L_NAME
    },

    getActiveCaptureStatus() {
      if (!this.activeCapture) return
      return this.activeCapture.status
    },
  },

  mounted() {
    this.$store.dispatch('getFirstCaptureProgressPage')

    EventBus.$on('captureProcessed', () => {
      this.$store.dispatch('closeModal') // To avoid stacked capture details modals

      this.openCaptureDetails(constants.ASSESSMENT_PROGRESS_TABLE_HEADERS.STATE, {
        ...this.activeCapture,
        status: this.$t(`captureDetails.processingTriggered`),
      })
    })
  },


  methods: {
    isCaptureChallenged(capture) {
      return capture.status.toLowerCase() === constants.ASSESSMENT_PROGRESS_STATE.CHALLENGED.toLowerCase() ||
        capture.status.toLowerCase() === constants.ASSESSMENT_PROGRESS_STATE.CANCELLED.toLowerCase()
    },

    toggleDropdown(capture) {
      if (this.dropdownOpen !== capture) {
        this.dropdownOpen = capture
      } else {
        this.dropdownOpen = ''
      }
    },

    isDropdownOpen(capture) {
      return this.dropdownOpen === capture
    },

    isComplete(capture) {
      return capture.status.toLowerCase() === constants.ASSESSMENT_PROGRESS_STATE.COMPLETED.toLowerCase()
    },

    hasTaskBreakdownOriginalFilename(capture) {
      return capture.hasTaskBreakdownOriginalFilename
    },

    convertTimeToMilliseconds(time) {
      return time * constants.UNIX_MILLISECONDS
    },

    isStateHeader(header) {
      return header === constants.ASSESSMENT_PROGRESS_TABLE_HEADERS.STATE
    },

    openUploadTaskBreakdown(capture) {
      if (this.isComplete(capture)) {
        this.$store.dispatch("showModal", this.constructModalSettings(
          constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_UPLOAD_TASK_BREAKDOWN,
          false,
          {
            customerID: capture.customerID,
            companyID: capture.companyID,
            userID: capture.userID,
            captureID: capture.captureID,
            metadataID: capture.metadataID,
            timezone: capture.timezone,
            startTime: this.convertTimeToMilliseconds(capture.startTime),
            endTime: this.convertTimeToMilliseconds(capture.endTime),
          },
          false,
          true,
        ))
      }
    },

    openManageTaskBreakdown(capture) {
      this.$store.dispatch("showModal", this.constructModalSettings(
        constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_MANAGE_TASK_BREAKDOWN,
        false,
        {
          customerID: capture.customerID,
          companyID: capture.companyID,
          userID: capture.userID,
          captureID: capture.captureID,
          metadataID: capture.metadataID,
          setID: capture.setID,
          timezone: capture.timezone,
          startTime: this.convertTimeToMilliseconds(capture.startTime),
          endTime: this.convertTimeToMilliseconds(capture.endTime),
        },
        true,
        true,
      ))
    },

    async openCaptureDetails(header, capture) {
      this.activeCapture = { ...capture } // Track the current capture to show details for

      if (this.isStateHeader(header) && this.userHasLBAssessorRole) {
        try {
          this.isLoading = true
          await this.$store.dispatch('getCaptureDetails', { captureID: this.activeCapture.id })

          this.$store.dispatch("showModal", this.constructModalSettings(
            constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_CAPTURE_DETAILS,
            false,
            {
              customerID: this.activeCapture.customerID,
              companyID: this.activeCapture.companyID,
              userID: this.activeCapture.userID,
              captureID: this.activeCapture.id,
              setID: this.activeCapture.setID,
              status: this.getActiveCaptureStatus,
              devices: this.getCaptureDetails || [],
              startTime: `${this.activeCapture.start} (${this.activeCapture.offset})`,
              timezone: capture.timezone,
            },
            true,
            true,
          ))
        } catch (err) {
          this.failModal(
            this.$t("pleaseTryAgain"),
            this.$t("somethingWentWrong"),
          )
        } finally {
          this.isLoading = false
        }
      }
    },

    async exportTaskAssessment(capture) {
      if (this.isComplete(capture) && this.hasTaskBreakdownOriginalFilename(capture)) {
        try {
          this.requestedDownload = true

          let taskAssessment = await this.$store.dispatch(
            "downloadTaskExport",
            {
              customerID: capture.customerID,
              companyID: capture.companyID,
              captureID: capture.id,
            }
          )

          const startTimeInMilliseconds = this.convertTimeToMilliseconds(capture.startTime)
          const captureStartTimeReadable = moment(moment.tz(startTimeInMilliseconds, capture.timezone)).format('YYYYMMDD-hhmmss')

          const csv = stringify(taskAssessment.data, {
            header: true,
            columns: taskAssessment.headers,
          })

          let anchor = document.createElement("a")
          anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
          anchor.target = "_blank"
          anchor.download = `${capture.setID}_${captureStartTimeReadable}_RULATaskExport.csv`
          anchor.click()
          anchor.remove()
        } catch (err) {
          this.failModal(
            this.$t("pleaseTryAgain"),
            this.$t("somethingWentWrong"),
          )
        } finally {
          this.requestedDownload = false
        }
      }
    },

    taskBreakdownUploadTooltip(capture) {
      if (!this.isComplete(capture)) {
        return {
          tooltipBodySettings: {
            view: {
              color: 'dark',
              textAlign: 'left',
              padding: 'extra-padding',
              width: 'taskbreakdown-width',
            },
            title: this.$t(`taskAssessment.uploadTaskBreakdown.uploadTaskBreakdownFileTooltip`),
          },

          body: {
            placement: 'bottom-left',
            offset: '0, -5',
          },
          allowHover: true,
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import "~design";
table,
th,
tr,
td {
  border: none;
}
table,
tr,
td {
  position: relative;
}
th {
  color: $color-table-row-hover-text;
}
td,
th {
  padding: 0.5rem;
}
td {
  height: 2rem;
  vertical-align: middle;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tbody {
  display: table-row-group;
  overflow: auto;
}
td {
  overflow: hidden;
  text-overflow: ellipsis;
}
table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 11px;
  flex-grow: 1;
  background-color: $color-box-background;
  color: $color-unselected-text;
  &.sticky-header th {
    position: sticky;
    top: 0;
    background-color: $color-box-background;
    z-index: $layer-sticky-table-header;
  }
}
.sz-AssessmentHistoryTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-dropdownArrow {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    &-open {
      transform: rotate(180deg);
      padding-left: 0;
      padding-right: 0.5rem;
    }
  }
  &-optionsBar {
    display: flex;
    background-color: $color-header-bar-backround;
    align-items: center;
    padding: 0.75rem 0;
    font-size: 13px;
    justify-content: space-between;
    &-title {
      justify-content: start;
      margin-left: 1rem;
    }
    @extend %font-heading;
    @include search-bar;
    &-edit {
      display: flex;
      align-items: center;
      &-icon {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    &-search {
      margin-left: auto;
      width: 30%;
      min-width: 9rem;
    }
  }
  &-wrapper {
    width: inherit;
    max-height: 60vh;
    overflow: scroll;
    @include scrollbar-widget;
    background-color: $color-box-background;
  }
  &-headers {
    &-header {
      &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
      }
      &-arrow {
        visibility: hidden;
        padding: 1px 3px;
      }
      &-firstName {
        width: 12%;
        overflow: hidden;
        padding-left: 1rem;
      }
      &-lastName {
        width: 12%;
        overflow: hidden;
      }
      &-setID {
        width: 5%;
      }
      &-start {
        width: 10%;
      }
      &-offset {
        width: 5%;
      }
      &-status {
        width: 5%;
      }
      &-company {
        width: 12%;
      }
      &-customer {
        width: 12%;
      }
      &-assessmentID {
        width: 12%;
      }
      &-jump {
        width: 10%;
      }
    }
  }
  &-row {
    &-item {
      text-align: left;
      &.firstName {
        min-width: 3rem;
        padding-left: 1rem;
      }
      &.lastName {
        min-width: 3rem;
      }
      &.setID {
        min-width: 4rem;
      }
      &.start {
        min-width: 10rem;
      }
      &.offset {
        min-width: 5rem;
      }
      &.status {
        min-width: 4rem;
      }
      &.company &.customer {
        min-width: 5rem;
      }
      &.jump {
        height: 2rem;
        min-width: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: $color-white;
        }
      }

      &.manageFile {
        min-width: 3.5rem;
        text-align: center;
      }
    }
    &-clear {
      &:hover {
        background: $color-table-row-hover;
        color: $color-table-row-hover-text;
      }
      cursor: pointer;
      background-color: $color-lifebooster-light-green;
      color: $color-text;
      font-size: 12px;
      padding: 0.25rem 0 0.25rem 0;
      max-width: 5rem;
      white-space: nowrap;
    }
  }
}
.buttons {
  background-color: $color-lifebooster-light-green;
  font-size: 0.688rem;
  font-family: $system-default-font-family;
  font-weight: 100;
  color: $color-text;
  text-transform: none;
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  &-disabled {
    background-color: $color-box-outline-dark;
    cursor: not-allowed;
  }

  &.centerAligned {
    text-align: center;
  }
}
.show {
  visibility: visible;
}
.up {
  transform: rotate(180deg);
}
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter {
  transform: translateY(-10px);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
