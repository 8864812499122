<template lang="pug">
    div(class="sz-LBAdminSettings")
        div(class="sz-LBAdminSettings-sectionTitle") {{ $t(`management.lbAdminDashboard.header`) }}

</template>

<script>
    export default {
        name: "LBAdminSettings",
    }
</script>

<style lang="scss" scoped>

</style>