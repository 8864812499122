<template lang="pug">
    div(class="sz-ExcludeAssessmentsTable")
        div(class="sz-ExcludeAssessmentsTable-optionsBar")
            div(class="sz-ExcludeAssessmentsTable-optionsBar-title") {{ selectedAssessmentsCount }}
            div(class="sz-ExcludeAssessmentsTable-optionsBar-divider")
            div(@click.stop="excludeAssessments",
                class="sz-ExcludeAssessmentsTable-optionsBar-edit")
                SvgIcon(
                    icon="visibilityOff",
                    width="1rem",
                    height="1rem",
                    class="sz-ExcludeAssessmentsTable-optionsBar-edit-icon")
                div(class="sz-ExcludeAssessmentsTable-optionsBar-edit-text") {{ $t(`management.assessmentDashboard.excludeAssessments.assessmentTable.excludeAssessments`) }}
            div(
                @click.stop="includeAssessments",
                class="sz-ExcludeAssessmentsTable-optionsBar-edit")
                SvgIcon(
                    icon="visibilityOn",
                    width="1rem",
                    height="1rem",
                    class="sz-ExcludeAssessmentsTable-optionsBar-edit-icon")
                div(class="sz-ExcludeAssessmentsTable-optionsBar-edit-text") {{ $t(`management.assessmentDashboard.excludeAssessments.assessmentTable.includeAssessments`) }}
            div(class="sz-ExcludeAssessmentsTable-optionsBar-search")
              PageNavigationButton(
                  nextPageEndpoint="getNextExclusionAssessmentsPage",
                  prevPageEndpoint="getPrevExclusionAssessmentsPage",
                  :disableNext="hasNextExclusionAssessmentPage",
                  :disablePrev="hasPrevExclusionAssessmentPage",
                  :currentPageNumber="exclusionAssessmentsCurrentPageNumber")
        div(v-if="updating",
            class="sz-ExcludeAssessmentsTable-wrapper")
            LoadingSpinner(color="light")
        div(v-else,
            class="sz-ExcludeAssessmentsTable-wrapper")
            table(class="sz-ExcludeAssessmentsTable-table sticky-header")
                thead
                    tr(class="sz-ExcludeAssessmentsTable-headers")
                        th()
                            div(
                                @click.stop="toggleSelectAll",
                                :class="`sz-ExcludeAssessmentsTable-row-checkbox-` + (isSelectAll ? 'checked ': 'unchecked')",
                                class="sz-ExcludeAssessmentsTable-row-checkbox")
                        th(
                            v-for="header of headers",
                            :class="`sz-ExcludeAssessmentsTable-headers-header-${header}`")
                            div(class="sz-ExcludeAssessmentsTable-headers-header-content")
                                span {{ $t(`management.assessmentDashboard.excludeAssessments.assessmentTable.headers.${header}`) }}
                tbody
                    tr(
                        v-if="exclusionAssessmentListLoading || !assessments.length",
                        class="sz-ExcludeAssessmentsTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-ExcludeAssessmentsTable-empty-cell")
                            span(
                                v-if="exclusionAssessmentListLoading"
                                class="sz-ExcludeAssessmentsTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.loading`) }}
                            span(
                                v-else-if="!assessments.length"
                                class="sz-ExcludeAssessmentsTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.assignAssessmentTags.assessmentTable.empty`) }}
                    tr(
                        v-if="!exclusionAssessmentListLoading && assessments.length",
                        v-for="assessment of assessments",
                        :key="assessment.id",
                        class="sz-ExcludeAssessmentsTable-row")
                        td()
                            div(
                                @click.stop="assessmentSelected(assessment)"
                                :class="`sz-ExcludeAssessmentsTable-row-checkbox-` + (isAssessmentSelected(assessment) ? 'checked ': 'unchecked')",
                                class="sz-ExcludeAssessmentsTable-row-checkbox"
                                )
                        td(
                            @click="assessmentSelected(assessment)",
                            v-for="header of headers",
                            :key="header",
                            v-truncated-tooltip="",
                            :id="`sz-ExcludeAssessmentsTable-row-item-${header}-${assessment.id}`"
                            :class="`sz-ExcludeAssessmentsTable-row-item ${header}`") {{ assessment[header] ?  joinAssessmentItems(assessment[header]) : null }}
</template>

<script>
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import { cloneDeep, get } from 'lodash'
import { TruncatedTooltip } from 'directives/truncatedTooltip'
import { userAssignableRoles } from 'helpers/permissionsHelper'
import modalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'
import SvgIcon from 'components/Shared/SvgIcon'
import EventBus from 'src/eventBus'
import moment from 'moment-timezone'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import PageNavigationButton from 'components/Navigation/PageNavigationButton'

export default {
  name: 'ExcludeAssessmentsTable',

  components: {
    SvgIcon,
    LoadingSpinner,
    PageNavigationButton,
  },

  directives: {
    TruncatedTooltip,
  },

  mixins: [modalSettings, formValidator],

  data() {
    return {
      currentSort: constants.EXCLUSION_ASSESSMENT_TABLE_HEADERS[1],
      currentSortDirection: 'down',
      selectedAssessments: [],
      selectAll: false,
      updating: false,
    }
  },

  computed: {
    ...mapGetters([
      'exclusionAssessmentListLoading',
      'exclusionAssessments',
      'companyMapper',
      'customerMapper',
      'exclusionAssessmentListFilters',
      'exclusionAssessmentsCurrentPageNumber',
      'hasNextExclusionAssessmentPage',
      'hasPrevExclusionAssessmentPage',
    ]),

    headers() {
      return constants.EXCLUSION_ASSESSMENT_TABLE_HEADERS
    },

    assessments() {
      // Need to clone so the start/end time does not get overwritten
      let assessmentsPage = cloneDeep(this.exclusionAssessments)
      return assessmentsPage
        .map((assessment) => {
          assessment['start'] = moment
            .tz(assessment.start * constants.UNIX_MILLISECONDS, assessment.timezone)
            .format('LL LTS')
          assessment['end'] = moment
            .tz(assessment.end * constants.UNIX_MILLISECONDS, assessment.timezone)
            .format('LL LTS')
          assessment['customer'] = this.customerMapper[assessment.customerID]
          assessment['company'] = this.companyMapper[assessment.companyID]
          assessment['excluded'] = this.$t(
            `management.assessmentDashboard.excludeAssessments.assessmentFilters.${
              constants.EXCLUSION_BOOLEAN_MAPPER[assessment.aggregationAllowed]
            }`
          )
          return assessment
        })
        .sort((a, b) => {
          let direction = 1
          let valA = this.sortCaseInsensitive
            ? get(a, this.currentSort, '').toLowerCase()
            : get(a, this.currentSort, '')
          let valB = this.sortCaseInsensitive
            ? get(b, this.currentSort, '').toLowerCase()
            : get(b, this.currentSort, '')
          if (
            this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.START ||
            this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.END
          ) {
            valA = moment.utc(valA)
            valB = moment.utc(valB)
          }
          if (this.currentSortDirection === 'down') direction = -1
          if (valA === null || valA === '' || valA.length === 0) return direction
          if (valB === null || valB === '' || valB.length === 0) return -1 * direction
          if (valA < valB) return -1 * direction
          if (valA > valB) return direction
          return 0
        })
    },

    selectedAssessmentsCount() {
      return `${this.$t(
        `management.assessmentDashboard.excludeAssessments.assessmentTable.selectedAssessments`
      )} (${this.selectedAssessments.length})`
    },

    isSelectAll() {
      return this.selectAll && this.selectedAssessments.length === this.assessments.length
    },

    sortCaseInsensitive() {
      return (
        this.currentSort === constants.EXCLUSION_ASSESSMENT_TABLE_HEADERS[3] ||
        this.currentSort === constants.EXCLUSION_ASSESSMENT_TABLE_HEADERS[4]
      )
    },
  },

  watch: {
    updating: async function () {
      if (this.updating === false) {
        await this.refreshAssessments()
      }
    },
  },

  mounted() {
    EventBus.$on('EXCLUSION_FILTERS_UPDATED', () => {
      this.selectedAssessments = []
    })
  },

  methods: {
    isAssessmentSelected(assessment) {
      return this.selectedAssessments.some(
        (selectAssessment) => selectAssessment.id === assessment.id
      )
    },

    assessmentSelected(assessment) {
      if (this.isAssessmentSelected(assessment)) {
        this.unSelectAssessment(assessment)
        return false
      } else {
        this.selectedAssessments.push(this.extractEditInfo(assessment))
        return true
      }
    },

    unSelectAssessment(selectedAssessment) {
      let currentlySelected = this.selectedAssessments.filter(
        (currentSelect) => currentSelect.id !== selectedAssessment.id
      )
      this.selectedAssessments = currentlySelected
    },

    toggleSelectAll() {
      this.selectAll
        ? (this.selectedAssessments = [])
        : (this.selectedAssessments = this.assessments.map((assessment) => {
            return this.extractEditInfo(assessment)
          }))
      this.selectAll = !this.selectAll
    },

    joinAssessmentItems(array) {
      if (Array.isArray(array) && array.length > 1) {
        return array.join(', ')
      } else {
        return array.toString()
      }
    },

    extractEditInfo(assessment) {
      return {
        id: assessment.id,
        captureID: assessment.captureID,
        assessmentMetadataID: assessment.metadataID,
      }
    },

    async excludeAssessments() {
      await this.updateAggregationAllowance(false)
    },

    async includeAssessments() {
      await this.updateAggregationAllowance(true)
    },

    async updateAggregationAllowance(aggregationAllowed) {
      this.updating = true
      try {
        await this.$store.dispatch('updateAggregationAllowance', {
          assessmentsInfo: this.selectedAssessments,
          aggregationAllowed: aggregationAllowed,
        })

        await this.successModal(
          aggregationAllowed === true
            ? this.$t(
                `management.assessmentDashboard.excludeAssessments.assessmentTable.includeSuccessMessage`
              )
            : this.$t(
                `management.assessmentDashboard.excludeAssessments.assessmentTable.excludeSuccessMessage`
              ),
          true,
          undefined,
          undefined,
          this.$t(`management.assessmentDashboard.excludeAssessments.assessmentTable.success`)
        )
      } catch (err) {
        await this.failModal(
          this.$t('pleaseTryAgain'),
          this.$t('somethingWentWrong'),
          () => (this.showConfirmModal = true)
        )
      }
      this.updating = false
    },

    async refreshAssessments() {
      await this.$store.dispatch('getFirstExclusionAssessmentsPage')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

table,
th,
tr,
td {
  border: none;
}

th {
  cursor: default;
  color: #e5e5e5;
}

td,
th {
  padding: 0.5rem;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  overflow: auto;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 11px;
  flex-grow: 1;
  background-color: $color-box-background;
  color: $color-unselected-text;

  &.sticky-header th {
    position: sticky;
    top: 0;
    background-color: $color-box-background;
  }
}

.sz-ExcludeAssessmentsTable {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-optionsBar {
    display: flex;
    background-color: $color-header-bar-backround;
    align-items: center;
    padding: 0.5rem;
    font-size: 13px;

    @extend %font-heading;

    &-title {
      padding-left: 0.25rem;
      display: flex;
      align-items: center;
      margin-right: 1.5rem;
      width: 9rem;
    }

    &-edit {
      @extend %button-layout;
      background-color: transparent;
      border-color: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 1.5rem;

      &:hover {
        background-color: $color-lifebooster-light-green;
      }

      &-text {
        margin-left: 0.35rem;
      }

      &-icon {
        align-self: center;
        margin-top: 0.25rem;
      }
    }

    &-search {
      margin-left: auto;
    }

    &-divider {
      border: 0.5px solid $color-box-silver;
      height: 2rem;
      margin-right: 1.25rem;
      opacity: 0.5;
    }
  }

  &-wrapper {
    width: inherit;
    max-height: 60vh;
    overflow: scroll;
    @include scrollbar-widget;
    background-color: $color-box-background;
  }

  &-headers {
    &-header {
      &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
      }

      &-arrow {
        visibility: hidden;
        padding: 1px 3px;
      }

      &-id {
        width: 25%;
      }

      &-start {
        width: 10%;
      }

      &-end {
        width: 10%;
      }

      &-customer {
        width: 10%;
      }

      &-company {
        width: 10%;
      }

      &-excluded {
        width: 30%;
      }
    }
  }

  &-row {
    cursor: pointer;

    &:hover {
      background: $color-table-row-hover;
      color: #e5e5e5;
    }

    &-item {
      text-align: left;

      &.id {
        width: 5rem;
      }

      &.start,
      &.end {
        min-width: 10rem;
      }

      &.customer {
        width: 3rem;
      }

      &.company {
        max-width: 1rem;
      }

      &.excluded {
        max-width: 1rem;
      }
    }

    &-checkbox {
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.125rem 0.3rem;
      border: $button-border;
      &-checked {
        background-color: $color-box-silver;
      }
    }
  }
}

.show {
  visibility: visible;
}

.up {
  transform: rotate(180deg);
}
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter {
  transform: translateY(-10px);
  opacity: 0;
}
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
