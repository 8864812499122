<template lang="pug">
    div(class="sz-DefaultAssessmentTagsTable")
        div(class="sz-DefaultAssessmentTagsTable-optionsBar")
            div(class="sz-DefaultAssessmentTagsTable-optionsBar-title") {{ selectedUsersCount }}
            div(class="sz-DefaultAssessmentTagsTable-optionsBar-divider")
            div(@click.stop="openEditDefaultAssessmentTags(assign)",
                class="sz-DefaultAssessmentTagsTable-optionsBar-edit")
                SvgIcon(
                    icon="indivAdd",
                    width="1rem",
                    height="1rem",
                    class="sz-DefaultAssessmentTagsTable-optionsBar-edit-icon")
                div(class="sz-DefaultAssessmentTagsTable-optionsBar-edit-text") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.applyDefaultTags`) }}
            div(
                @click.stop="openEditDefaultAssessmentTags(remove)",
                class="sz-DefaultAssessmentTagsTable-optionsBar-edit")
                SvgIcon(
                    icon="indivRemove",
                    width="1rem",
                    height="1rem",
                    class="sz-DefaultAssessmentTagsTable-optionsBar-edit-icon")
                div(class="sz-DefaultAssessmentTagsTable-optionsBar-edit-text") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.removeDefaultTags`) }}
            div(class="sz-DefaultAssessmentTagsTable-optionsBar-search")
                input(
                    type="text",
                    v-model="search",
                    placeholder="Type here to search",
                    class="sz-DefaultAssessmentTagsTable-optionsBar-search-input")
                SvgIcon(
                    icon="search",
                    width="0.5rem",
                    height="0.5rem",
                    class="sz-DefaultAssessmentTagsTable-optionsBar-search-icon")
        div(v-if="applyDefaultAssessmentTagsListLoading",
            class="sz-DefaultAssessmentTagsTable-wrapper")
            LoadingSpinner(color="light")
        div(v-else,
            class="sz-DefaultAssessmentTagsTable-wrapper")
            table(class="sz-DefaultAssessmentTagsTable-table sticky-header")
                thead
                    tr(class="sz-DefaultAssessmentTagsTable-headers")
                        th()
                            div(
                                @click.stop="toggleSelectAll",
                                :class="`sz-DefaultAssessmentTagsTable-row-checkbox-` + (isSelectAll ? 'checked ': 'unchecked')",
                                class="sz-DefaultAssessmentTagsTable-row-checkbox")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-DefaultAssessmentTagsTable-headers-header-${header}`")
                            div(class="sz-DefaultAssessmentTagsTable-headers-header-content")
                                span {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.headers.${header}`) }}
                                SvgIcon(
                                    icon="arrow",
                                    width="10",
                                    height="10",
                                    class="sz-DefaultAssessmentTagsTable-headers-header-arrow",
                                    :class="[currentSortDirection, header === currentSort ? 'show' : '']")
                tbody
                    tr(
                        v-if="applyDefaultAssessmentTagsListLoading || !users.length",
                        class="sz-DefaultAssessmentTagsTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-DefaultAssessmentTagsTable-empty-cell")
                            span(
                                v-if="applyDefaultAssessmentTagsListLoading"
                                class="sz-DefaultAssessmentTagsTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.loading`) }}
                            span(
                                v-else-if="!users.length"
                                class="sz-DefaultAssessmentTagsTable-empty-cell-loading") {{ $t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.empty`) }}
                    tr(
                        v-if="!applyDefaultAssessmentTagsListLoading && users.length",
                        v-for="user of users",
                        :key="user.userID",
                        class="sz-DefaultAssessmentTagsTable-row")
                        td()
                            div(
                                @click.stop="userSelected(user)"
                                :class="`sz-DefaultAssessmentTagsTable-row-checkbox-` + (isUserSelected(user) ? 'checked ': 'unchecked')",
                                class="sz-DefaultAssessmentTagsTable-row-checkbox"
                                )
                        td(
                            @click="userSelected(user)",
                            v-for="header of headers",
                            :key="header",
                            v-truncated-tooltip="",
                            :id="`sz-DefaultAssessmentTagsTable-row-item-${header}-${user.userID}`"
                            :class="`sz-DefaultAssessmentTagsTable-row-item ${header}`") {{ user[header] ?  joinUserItems(user[header]) : null }}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { get } from 'lodash'
    import { TruncatedTooltip } from 'directives/truncatedTooltip'
    import modalSettings from 'mixins/modalSettings'
    import formValidator from 'mixins/formValidator'
    import SvgIcon from 'components/Shared/SvgIcon'
    import EventBus from 'src/eventBus'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import { getOCTIntersection } from '@/helpers/orgChartTagHelpers'

    export default {
        name: 'DefaultAssessmentTagsTable',

        components: {
            SvgIcon,
            LoadingSpinner,
        },

        directives: {
            TruncatedTooltip,
        },

        mixins: [
            modalSettings,
            formValidator,
        ],

        props: {
            viewerOCTs: {
                type: Array,
                required: true,
            },
        },

        data () {
            return {
                currentSort: constants.APPLY_DEFAULT_ASSESSMENT_TAGS_TABLE_HEADERS[0],
                currentSortDirection: 'down',
                search: '',
                selectedUsers: [],
                selectAll: false,
                showEditOptions: false,
            }
        },

        computed: {
            ...mapGetters([
                'applyDefaultAssessmentTagsListLoading',
                'applyDefaultAssessmentTagsUsers',
                'assessmentTagMapper',
                'applyDefaultAssessmentTagsFilters',
                'companyTagMapper',
            ]),

            headers () {
                return constants.APPLY_DEFAULT_ASSESSMENT_TAGS_TABLE_HEADERS
            },

            users () {
                return this.applyDefaultAssessmentTagsUsers
                    .map((user) => {
                        user['orgChartTags'] = this.companyTagMapper[getOCTIntersection(user.membertags.map((tag) => { return tag.id }), this.viewerOCTs)]
                        user['defaultAssessmentTagNames'] = user.defaultAssessmentTags ? user.defaultAssessmentTags.map(tag => { return this.assessmentTagMapper[tag.id] }) : []
                        return user
                    })
                    .filter((user) =>
                        (get(user, 'firstName', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
                        (get(user, 'lastName', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
                        (get(user, 'job', '').toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    )
                    .sort((a,b) => {
                        let direction = 1
                        let valA = this.sortCaseInsensitive ? get(a, this.currentSort, '').toLowerCase() : get(a, this.currentSort, '')
                        let valB = this.sortCaseInsensitive ? get(b, this.currentSort, '').toLowerCase() : get(b, this.currentSort, '')
                        if (this.currentSortDirection === 'down') direction = -1
                        if (valA === null || valA === '' || valA.length === 0) return direction
                        if (valB === null || valB === '' || valB.length === 0) return -1 * direction
                        if (valA < valB) return -1 * direction
                        if (valA > valB) return direction
                        return 0
                    })
            },

            selectedUsersCount () {
                return `${this.$t(`management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.selectedUsers`)} (${this.selectedUsers.length})`
            },

            isSelectAll () {
                return this.selectAll && this.selectedUsers.length === this.users.length
            },

            sortCaseInsensitive() {
                return this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.F_NAME
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.L_NAME
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.JOB
                || this.currentSort === constants.ASSESSMENT_TABLE_HEADERS.GENDER
            },

            assign() {
                return constants.UPDATE_TAG_OPTIONS.ASSIGN
            },

            remove() {
                return constants.UPDATE_TAG_OPTIONS.REMOVE
            },
        },

        mounted () {
            EventBus.$on('UPDATE_SELECTED_USERS', userID => {
                this.selectedUsers = this.selectedUsers.filter(user => user.userID !== userID)
            })

            EventBus.$on('DEFAULT_TAGS_FILTERS_UPDATED', () => {
                this.selectedUsers = []
            })

            EventBus.$on('DEFAULT_TAGS_UPDATED', async() => {
                await this.refreshUsers()
            })
        },

        beforeDestroy () {
            EventBus.$off('UPDATE_SELECTED_USERS')

            EventBus.$off('DEFAULT_TAGS_FILTERS_UPDATED')

            EventBus.$off('DEFAULT_TAGS_UPDATED')
        },

        methods: {
            setCurrentSort (header) {
                if (header === this.currentSort) {
                    this.currentSortDirection = this.currentSortDirection === 'up' ? 'down' : 'up'
                }
                this.currentSort = header
            },

            isUserSelected (user) {
                return this.selectedUsers.some(selectUser => selectUser.userID === user.userID)
            },

            userSelected (user) {
                if (this.isUserSelected(user)){
                    this.unSelectUser(user)
                    return false
                } else {
                    this.selectedUsers.push(user)
                    return true
                }
            },

            unSelectUser (user) {
                let currentlySelected = this.selectedUsers.filter(currentSelect => currentSelect.userID !== user.userID)
                this.selectedUsers = currentlySelected
            },

            toggleSelectAll () {
                this.selectAll ?
                    this.selectedUsers = [] :
                    this.selectedUsers = this.users
                this.selectAll = !this.selectAll
            },

            joinUserItems (array) {
                if (Array.isArray(array) && array.length > 1) {
                    return array.join(', ')
                } else {
                    return array.toString()
                }
            },

            async refreshUsers () {
                if (Object.keys(this.applyDefaultAssessmentTagsFilters).length > 0) {
                    await this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', this.applyDefaultAssessmentTagsFilters)
                } else {
                    await this.$store.dispatch('getUsersForApplyDefaultAssessmentTags', {})
                }
            },

            openEditDefaultAssessmentTags(editType) {
                if (this.selectedUsers.length > 0) {
                    this.$store.dispatch('showModal', this.constructModalSettings(
                        constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_EDIT_DEFAULT_ASSESSMENT_TAGS,
                        false,
                        {
                            users: this.selectedUsers,
                            editType: editType,
                        },
                        true,
                        true)
                    )
                } else {
                    this.failModal(
                       this.$t("management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.noneSelectedMesssage"),
                       this.$t("management.assessmentDashboard.applyDefaultAssessmentTags.assessmentTable.noneSelectedWarning")
                    )
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: #E5E5E5;
    }

    td, th {
        padding: 0.5rem;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;

    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top:0;
            background-color: $color-box-background ;
        }
    }

    .sz-DefaultAssessmentTagsTable {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 50vw;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem;
            font-size: 13px;

            @extend %font-heading;
            @include search-bar;

            &-title {
                padding-left: 0.25rem;
                display: flex;
                align-items: center;
                width: 8.5rem;
            }

            &-edit {
                @extend %button-layout;
                background-color: transparent;
                border-color: transparent;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 1.5rem;

                &:hover {
                    background-color: $color-lifebooster-light-green;
                }

                &-text {
                    margin-left: 0.35rem;
                }

                &-icon {
                    align-self: center;
                    margin-top: 0.25rem;
                }
            }

            &-search {
                margin-left: auto;
                width: 30%;
                min-width: 9rem;
            }

            &-divider {
                border: 0.5px solid $color-box-silver;
                height: 2rem;
                margin-right: 1rem;
                opacity: 0.5;
            }
        }

        &-wrapper {
            width: inherit;
            max-height: 60vh;
            overflow: scroll;
            @include scrollbar-widget;
            background-color: $color-box-background;
        }

        &-headers {
            &-header {
                &-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                }

                &-arrow {
                    visibility: hidden;
                    padding: 1px 3px;
                }

                &-firstName {
                    width: 11%;
                    overflow: hidden;
                }

                &-lastName {
                    width: 11%;
                    overflow: hidden;
                }

                &-start {
                    width: 11%;
                }

                &-end {
                    width: 11%;
                }

                &-job {
                    width: 11%;
                    overflow: hidden;
                }

                &-gender {
                    width: 8%;
                }

                &-orgChartTags {
                    width: 15%;
                }

                &-assessmentTags {
                    width: 15%;
                }

                &-excluded {
                    width: 5%;
                }
            }
        }

        &-row {
            cursor: pointer;

            &:hover {
                background: $color-table-row-hover;
                color: #E5E5E5
            }

            &-item {
                text-align: left;

                &.firstName, &.lastName {
                    max-width: 3rem;
                }

                &.start, &.end {
                    min-width: 10rem;
                }

                &.job {
                    width: 3rem;
                }

                &.gender {
                    max-width: 1rem;
                }

                &.orgChartTags, &.assessmentTags {
                    min-width: 8rem;
                }

                &.excluded {
                    max-width: 1rem;
                }
            }

            &-checkbox {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.125rem 0.3rem;
                border: $button-border;
                &-checked {
                    background-color: $color-box-silver;
                }
            }
        }
    }

    .show {
        visibility: visible;
    }

    .up {
        transform: rotate(180deg);
    }
    .slide-enter-active {
        transition: all .3s ease;
    }
    .slide-leave-active {
        transition: all .2s ease;
    }
    .slide-enter {
        transform: translateY(-10px);
        opacity: 0;
    }
    .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }
</style>
