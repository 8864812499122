<template lang="pug">
    form(class="sz-ExcludeAssessmentsFilters")
        div(class="sz-ExcludeAssessmentsFilters-body")
            div(class="sz-ExcludeAssessmentsFilters-body-title") {{ "Filter By" }}
            div(class="sz-ExcludeAssessmentsFilters-body-section")
                FormSectionSingleSelectSearchable(
                    class="sz-ExcludeAssessmentsFilters-body-section-searchable",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.customer`)",
                    :options="customerOptions",
                    :defaultSelectedTags="defaultCustomer",
                    width="fullWidth",
                    @updateSelectedOption="updateSelectedCustomerID")
            div(class="sz-ExcludeAssessmentsFilters-body-section")
                FormSectionSingleSelectSearchable(
                    class="sz-ExcludeAssessmentsFilters-body-section-searchable",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.company`)",
                    :options="companyOptions",
                    :defaultSelectedTags="defaultCompany",
                    width="fullWidth",
                    @updateSelectedOption="updateSelectedCompanyID",
                    ref="companySelector")
            div(class="sz-ExcludeAssessmentsFilters-body-section")
                FormSectionInput(
                    class="sz-ExcludeAssessmentsFilters-body-section-input",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.assessmentID`)",
                    textSize="content",
                    :useInputTag="true",
                    @updated="updateSelectedAssessmentID")
            div(class="sz-ExcludeAssessmentsFilters-body-section")
                FormSectionToggle(
                    class="sz-ExcludeAssessmentsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.excluded`)",
                    :defaultChecked="defaultExclusionOption",
                    :options="exclusionOptions",
                    @changed="updateAggregationAllowance")
            div(class="sz-ExcludeAssessmentsFilters-body-section-preset")
                FormSectionDropdownSingleSelect(
                    class="sz-ExcludeAssessmentsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.presets`)",
                    :defaultSelectedOption="defaultPreset",
                    :options="presetOptions",
                    :placeholder="presetPlaceholder",
                    @updateSelectedOption="updateSelectedPreset",
                    ref="presetPicker",
                    class="sz-FormEditWidget-row-date-presets")
            div(class="sz-ExcludeAssessmentsFilters-body-section-time")
                FormSectionDateRangePicker(
                    class="sz-ExcludeAssessmentsFilters-body-section-select",
                    :title="$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.interval`)",
                    :defaultSelectedInterval="defaultInterval",
                    @updateSelectedDateRange="updateSelectedInterval",
                    ref="datePicker")
            div(class="sz-ExcludeAssessmentsFilters-body-footer")
                div(
                    @click="resetFilters",
                    class="sz-ExcludeAssessmentsFilters-body-footer-buttons \
                        sz-ExcludeAssessmentsFilters-body-footer-buttons-reset") {{ $t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.reset`)}}
                div(
                    type="submit",
                    @click="applyFilters",
                    class="sz-ExcludeAssessmentsFilters-body-footer-buttons \
                        sz-ExcludeAssessmentsFilters-body-footer-buttons-apply") {{ $t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.apply`)}}
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import FormSectionTagSelection from 'components/Shared/FormSectionTagSelection'
    import FormSectionDateRangePicker from 'components/Shared/FormSectionDateRangePicker'
    import FormSectionToggle from 'components/Shared/FormSectionToggle'
    import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
    import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import EventBus from 'src/eventBus'

    export default {
        name: "ExcludeAssessmentsFilters",

        components: {
            FormSectionTagSelection,
            FormSectionDateRangePicker,
            FormSectionToggle,
            FormSectionSingleSelectSearchable,
            FormSectionInput,
            FormSectionDropdownSingleSelect,
        },

        data () {
            return {
                selectedCustomerID: '',
                selectedCompanyID: '',
                selectedAssessmentID: '',
                interval: '',
                aggregationAllowed: undefined,
            }
        },

        computed: {
            ...mapGetters([
                'exclusionAssessmentListFilters',
                'companyMapper',
                'customerMapper',
                'allCompanies',
                'availableCustomers',
            ]),

            defaultCustomer () {
                return this.exclusionAssessmentListFilters.customerID || ''
            },

            defaultCompany () {
                return this.exclusionAssessmentListFilters.companyID || ''
            },

            defaultAssessmentID () {
                return this.exclusionAssessmentListFilters.assessmentID || ''
            },

            defaultPreset () {
                let interval = this.exclusionAssessmentListFilters.interval || ''
                let intervalIsInPresetMap = Object.values(constants.DATE_PRESET_INTERVAL_MAP).includes(interval)
                return intervalIsInPresetMap ? constants.INTERVAL_DATE_PRESET_MAP[interval] : interval
            },

            defaultInterval () {
                return this.exclusionAssessmentListFilters.interval || ''
            },

            defaultExclusionOption () {
                return (this.exclusionAssessmentListFilters.aggregationAllowed !== undefined) ? !this.exclusionAssessmentListFilters.aggregationAllowed : false
            },

            customerOptions () {
                if (this.selectedCompanyID) {
                    let customerID = (this.allCompanies.find(company => company.id === this.selectedCompanyID)).customerID
                    return [(this.availableCustomers.find(customer => customer.id === customerID)).name]
                } else {
                    return this.availableCustomers.map(customer => customer.name)
                }
            },

            companyOptions () {
                if (this.selectedCustomerID) {
                    // Only display companies that are under the selected customer
                    return this.allCompanies.filter(company => company.customerID === this.selectedCustomerID).map(company => company.name)
                } else {
                    return this.allCompanies.map(company => company.name)
                }
            },

            presetOptions () {
                let presetOptions = Object.values(constants.EXPORT_DATE_PRESETS).map((preset) => {
                    return {
                        name: this.$t(`modals.modalEditWidget.presetTypes.${preset}`),
                        type: preset,
                    }
                })

                return presetOptions
            },

            exclusionOptions () {
                return [this.$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.no`), this.$t(`management.assessmentDashboard.excludeAssessments.assessmentFilters.yes`)]
            },

            presetPlaceholder () {
                return this.$t(`modals.modalEditWidget.presetPlaceholder`)
            },

            intervalIsPreset () {
                return this.interval ? (Object.values(constants.DATE_PRESET_INTERVAL_MAP)).includes(this.interval)
                                     || Object.values(constants.EXPORT_DATE_PRESETS).includes(this.interval)
                                     : false
            },

            currentFilters () {
                return {
                  customerID: this.selectedCustomerID,
                  companyID: this.selectedCompanyID,
                  assessmentID: this.selectedAssessmentID ? this.formatAssessmentID(this.selectedAssessmentID) : undefined,
                  interval: this.intervalIsPreset ? constants.DATE_PRESET_INTERVAL_MAP[this.interval] : this.interval,
                  // Only show assessments that have aggregation denied or do not apply this filter
                  aggregationAllowed: (this.aggregationAllowed == false) ? this.aggregationAllowed : undefined,
                }
            },
        },

        watch: {
            interval () {
                this.intervalIsPreset ?  this.clearDatePicker() : this.clearPreset()
            },
        },

        mounted () {
            this.selectedCustomerID = this.exclusionAssessmentListFilters.customerID
            this.selectedCompanyID = this.exclusionAssessmentListFilters.companyID
            this.selectedAssessmentID = this.exclusionAssessmentListFilters.assessmentID
        },

        methods: {
            updateSelectedCustomerID(customer) {
                this.selectedCustomerID = Object.keys(this.customerMapper).find(key => this.customerMapper[key] === customer)
                this.$refs.companySelector.clearSelection()
            },

            updateSelectedCompanyID(company) {
                this.selectedCompanyID = Object.keys(this.companyMapper).find(key => this.companyMapper[key] === company)
            },

            updateSelectedAssessmentID(assessmentID) {
                this.selectedAssessmentID = assessmentID
            },

            updateSelectedPreset(newPreset) {
                this.interval = newPreset
            },

            updateSelectedInterval (newInterval) {
                this.interval = newInterval
            },

            updateAggregationAllowance (excludedOnly) {
                this.aggregationAllowed = !excludedOnly
            },

            clearPreset () {
                this.$refs.presetPicker.clearSelectedOption()
            },

            clearDatePicker () {
                this.$refs.datePicker.clearDatePickers()
            },

            formatAssessmentID (assessmentID) {
                // When copying an assessment ID from one of the assessment pages, the assessment ID may be preprended with the risk module
                // Ex. M-${assessmentID}, T-${assessmentID}
                // This prepend needs to be removed in order for the assessment ID to be usable for querying
                if ((Object.values(constants.RISK_MODULES_SHORT).map(risk => `${risk}-`)).includes(assessmentID.substring(0,2))) {
                    return assessmentID.substring(2).toLowerCase()
                } else {
                    return assessmentID.toLowerCase()
                }
            },

            async resetFilters () {
                this.selectedCustomerID = ''
                this.selectedCompanyID = ''
                this.selectedAssessmentID = ''
                this.interval = ''
                this.aggregationAllowed = undefined

                EventBus.$emit(constants.EVENTS.CLEAR_SELECTION)

                this.$store.dispatch('updateExclusionAssessmentListFilters', this.currentFilters),
                await this.$store.dispatch('getFirstExclusionAssessmentsPage'),

                this.publishUpdateEvent()
            },

            async applyFilters () {
                await this.$store.dispatch('updateExclusionAssessmentListFilters', this.currentFilters)
                await this.$store.dispatch('getFirstExclusionAssessmentsPage')

                this.publishUpdateEvent()
            },

            publishUpdateEvent () {
                EventBus.$emit('EXCLUSION_FILTERS_UPDATED')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ExcludeAssessmentsFilters {
        position: relative;
        width: inherit;
        height: 100%;

        &-body {
            background-color: $color-tooltip-dark;
            max-height: 61.5vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem 0 1rem 0;
            overflow-y: scroll;
            overflow-x: hidden;
            @include scrollbar-widget;

            &-title {
                align-self: flex-start;
                padding: 0rem 1rem 0.5rem 1rem;
                @extend %font-heading;
                font-size: 14px;
            }

            &-section {
                padding: 0.25rem 1rem 0.25rem 1rem;

                &-preset {
                    padding: 0.5rem 1rem 0.5rem 1rem;
                    width: 90.75%;
                }

                &-time {
                    padding: 0.5rem 1rem 0rem 1rem;
                    width: 92%;
                }

                &-searchable {
                    padding-bottom: 1rem;
                }

                &-input {
                    width: 107%;
                    padding-bottom: 1rem;
                }
            }

            &-footer {
                padding-top: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &-buttons {
                    cursor: pointer;
                    @extend %font-topbar-heading;

                    &-reset {
                        color: $color-white;
                        margin: 0 1rem;
                        font-size: 12px;
                    }

                    &-apply {
                        @extend %button-layout;
                        background-color: $color-lifebooster-light-green;
                        padding: 0.5rem 1rem;
                        border-radius: 0;
                    }
                }
            }
        }
    }
</style>
