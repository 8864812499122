<template lang="pug">
    div(class="sz-DropdownAssessmentNavigator")
        div(v-if="loading")
            LoadingSpinner
        div(
            v-else,
            v-click-away="close",
            v-for="riskModule in assessmentPages",
            :key="riskModule",
            @click.stop="goToAssessmentPage(riskModule)",
            class="sz-DropdownAssessmentNavigator-module") {{ $t(`motionAssessment.assessmentPages.${riskModule}`) }}
</template>

<script>
    import constants from 'helpers/constants'
    import { ClickAway } from 'directives/clickAway'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'

    export default {
        name: 'DropdownAssessmentNavigator',

        components: {
            LoadingSpinner,
        },

        directives: {
            ClickAway,
        },

        props: {
            captureID: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                loading: false,
            }
        },

        computed: {
            assessmentPages () {
                return Object.values(constants.ASSESSMENT_PAGES)
            },
        },

        methods: {
            getRiskType (riskModule) {
                if (riskModule === constants.PAGES.ASSESSMENT.EDITOR) {
                    return constants.MODULE_RISK_TYPES[constants.RISK_MODULES.MOTION][0]
                } else {
                    return constants.MODULE_RISK_TYPES[riskModule][0]
                }
            },

            async goToAssessmentPage (riskModule) {
                this.loading = true

                await this.$store.dispatch('updateSelectedModule', riskModule)

                let routeName = (riskModule === constants.PAGES.ASSESSMENT.EDITOR) ?
                    constants.PAGES.ASSESSMENT.EDITOR :
                    constants.PAGES.ASSESSMENT.MODULE
                    
                let assessmentInfo = await this.$store.dispatch('getAssessmentPageMetadataFromCapture', { captureID: this.captureID })

                // If navigating to an assessment in another customer/company, we need to update the current state
                await this.$store.dispatch('updateCurrentCustomerAndCompany', { customerID: assessmentInfo.customerID, companyID: assessmentInfo.companyID })
                await this.$store.dispatch('updateWorkerId', assessmentInfo.userID)
                await this.$store.dispatch('updateAssessments')
                await this.$store.dispatch('updateSelectedAssessmentTimestamp', assessmentInfo.start)
                await this.$store.dispatch('updateWorkerCard', { workerId: assessmentInfo.userID })

                this.$router.push({
                    name: routeName,
                    params: {
                        workerId: assessmentInfo.userID,
                        captureIdx: assessmentInfo.captureID,
                        assessmentIdx: assessmentInfo.id,
                        riskType: this.getRiskType(riskModule),
                        assessmentDate: assessmentInfo.start,
                        assessmentType: riskModule,
                    },
                })

                this.loading = false
            },

            close () {
                this.$emit('closeDropdown')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-DropdownAssessmentNavigator {
        position: absolute;
        width: 5rem;
        z-index: $layer-dropdown-z-index;
        background-color: $color-dropdown-background;
        font-size: 0.75rem;
        overflow: hidden;

        &-module {
            width: 100%;
            padding: 0.25rem 0.5rem;
            cursor: pointer;
            text-align: left;

            &:hover {
                background-color: $color-lifebooster-medium-green;
            }
        }
    }
</style>
