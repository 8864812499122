<template lang="pug">
    div(class="sz-ManagementDashboard")
        div(class="sz-ManagementDashboard-settings")
           component(:is="settingsComponent")

</template>

<script>
    import CustomerSettings from 'components/ManagementDashboard/CustomerSettings'
    import CompanySettings from 'components/ManagementDashboard/CompanySettings'
    import ProfileSettings from 'components/ManagementDashboard/ProfileSettings'
    import LBAdminSettings from 'components/ManagementDashboard/LBAdminSettings'
    import AssessmentSettings from 'components/ManagementDashboard/AssessmentSettings'

    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "ManagementDashboard",

        components: {
            CustomerSettings,
            CompanySettings,
            ProfileSettings,
            LBAdminSettings,
            AssessmentSettings,
        },

        computed: {
            ...mapGetters([
                'currentSettingsPage',
            ]),

            settingsComponent () {
                return constants.SETTING_PAGE_COMPONENT_NAME_MAP[this.currentSettingsPage]
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-ManagementDashboard {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        &-sidebar {
            width: 15rem;
            display: flex;
        }

        &-settings {
            flex-grow: 1;
            padding: 0.25rem;
            overflow-y: hidden;
        }
    }

</style>
