<template lang="pug">
    div(class="sz-PageNavigation")
        div(class="sz-PageNavigation-prevPage" @click="handlePrevPageClick()" v-if="disablePrev") {{ "<" }}
        div(class="sz-PageNavigation-gap") {{ currentPageNumber }}
        div(class="sz-PageNavigation-nextPage" @click="handleNextPageClick()" v-if="disableNext") {{ ">" }}
</template>

<script>
    export default {
        name: 'PageNavigationButton',

        props: {
            nextPageEndpoint: {
                type: String,
                required: true,
            },

            prevPageEndpoint: {
                type: String,
                required: true,
            },

            disableNext: {
                type: Boolean,
                required: true,
            },

            disablePrev: {
                type: Boolean,
                required: true,
            },

            currentPageNumber: {
                type: Number,
                required: true,
            },

            filterForm: {
                type: Object,
                required: false,
                default: () => {},
            },
        },

        methods: {
            handleNextPageClick() {
                this.$store.dispatch(this.nextPageEndpoint, this.filterForm)
            },

            handlePrevPageClick() {
                this.$store.dispatch(this.prevPageEndpoint, this.filterForm)
            },
        },
}
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-PageNavigation {
        max-width: 20rem;
        display: flex;
        flex-direction: row;
        font-size: 18px;
        padding-right: 1rem;

        &-prevPage {
            padding-right: 1rem;
            cursor: pointer;
        }
        &-nextPage {
            padding-left: 1rem;
            cursor: pointer;
        }

        &-gap {
            margin: 0.1rem;
        }
    }
</style>
