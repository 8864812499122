import { render, staticRenderFns } from "./JumpToAssessment.vue?vue&type=template&id=889a202c&scoped=true&lang=pug"
import script from "./JumpToAssessment.vue?vue&type=script&lang=js"
export * from "./JumpToAssessment.vue?vue&type=script&lang=js"
import style0 from "./JumpToAssessment.vue?vue&type=style&index=0&id=889a202c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889a202c",
  null
  
)

export default component.exports